import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkmark from '../../assets/checked.png';
import NotCheckmark from '../../assets/not-available.png';
import MotionRow from '../generalcomponents/MotionRow';
import Card from '../cards/Card';
import CardTitle from '../typography/CardTitle';
import CardSubtitle from '../typography/CardSubtitle';
import IconComponent from '../icons/IconComponent';

const WordLengthCard = (props) => {
    const { text } = props;
    const [title, setTitle] = useState('God længde');
    const [subtitle, setSubtitle] = useState('Ordet er ikke for langt');
    const [iconSource, setIconSource] = useState(Checkmark);

    useEffect(() => {
        if (text.length > 14) {
            setTitle('For langt domæne');
            setSubtitle('Kortere domæner er bedre');
            setIconSource(NotCheckmark);
        } else {
            setTitle('God længde');
            setSubtitle('Ordet er ikke for langt');
            setIconSource(Checkmark);
        }
    }, [text])

    return (
      <MotionRow lg="6">
        <Card padding={30}>
          <CardTitle>{title}</CardTitle>
          <CardSubtitle>{subtitle}</CardSubtitle>
          <IconComponent right={40} top={30} src={iconSource} alt="analyse-ikon" />
        </Card>
      </MotionRow>
    );
}
  
WordLengthCard.propTypes = {
  text: PropTypes.string.isRequired
};

export default WordLengthCard;