import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import Card from '../cards/Card';
import UppercaseColoredText from '../typography/UppercaseColoredText';
import TableItem from './TableItem';
import { isMobile } from 'react-device-detect';

interface ComponentProps {
  data: Array<Domain>
};

interface Domain {
  id: number,
  word: string
};

const TableComponent: React.FunctionComponent<ComponentProps> = ({ data }) => {
  const [showItems, setShowItems] = useState<boolean>(true);

  useEffect(() => {
    if (isMobile) {
      setShowItems(false);
    }
  }, [])

  return (
    <Card>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th><UppercaseColoredText>Navn</UppercaseColoredText></th>
            {showItems && ( 
              <>
                <th><UppercaseColoredText>Se domæne</UppercaseColoredText></th>
                <th></th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((item: Domain, key) =>
            <TableItem
              key={item.id}
              item={item}
            />
          )}
        </tbody>
      </Table>
    </Card>
  );
}

export default TableComponent;