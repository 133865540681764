import React from 'react';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';

interface ComponentProps {
    children: string | string[],
    fontSize?: number
}

const UppercaseColoredText: React.FunctionComponent<ComponentProps> = ({ children, fontSize }) => {

    const textStyle = {
        fontSize: fontSize,
        fontFamily: Variables.fontFamilyHeadline,
        fontWeight: Variables.fontWeightHeadline,
        color: Colors.themeColor,
        textTransform: 'uppercase',
        paddingBottom: 0,
        marginBottom: 0
    } as React.CSSProperties;

    return (
        <p style={textStyle}>{children}</p>
    );
}

UppercaseColoredText.defaultProps = {
    fontSize: 15
  } as Partial<ComponentProps>;

export default UppercaseColoredText;