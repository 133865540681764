import React, { FormEvent } from 'react';
import Colors from '../../constants/Colors';
import { FaSearch } from 'react-icons/fa';
import CustomButton from '../buttons/CustomButton';

interface ComponentProps {
    value: string,
    handleChange: (event: FormEvent<HTMLInputElement>) => void,
    handleSubmit: (event: any) => void,
    placeholder?: string
};

const AnalysisSearchBar: React.FunctionComponent<ComponentProps> = ({ value, handleChange, handleSubmit, placeholder }) => {
    return(
        <div style={searchContainer}>
            <form className="form-inline" style={inlineForm} onSubmit={handleSubmit}>
                <FaSearch 
                    style={searchIcon}
                    size={25}
                />
                <input
                type="text"
                value={value}
                placeholder={placeholder}
                style={textInput}
                onChange={handleChange}
                />
                <CustomButton onClick={handleSubmit} />
            </form>
        </div>
    );
}

AnalysisSearchBar.defaultProps = {
    placeholder: 'Search...'
} as Partial<ComponentProps>;

const textInput = {
    boxSizing: 'border-box',
	fontSize: 18,
	background: Colors.white,
	color: Colors.grey,
    paddingTop: 13,
    paddingBottom: 15,
    paddingRight: 13,
    paddingLeft: 50,
    border: 'none',
    outline: 'none',
	width: '75%',
	fontWeight: 'normal',
    borderRadius: 5,
} as React.CSSProperties;

const searchContainer = {
    backgroundColor: Colors.themeColor,
    paddingBottom: 15,
    borderRadius: 5,
    display: 'inline'
} as React.CSSProperties;

const searchIcon = {
    color: Colors.lightGrey,
    position: 'absolute',
    left: 30,
    top: 15,
} as React.CSSProperties;

const inlineForm = {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center'
} as React.CSSProperties;

export default AnalysisSearchBar;