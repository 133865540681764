import React from 'react';
import Dot from '../components/generalcomponents/Dot';
import Colors from '../constants/Colors';
import { GoogleAnalyticsService } from '../services/GoogleAnalyticsService';
import Headline from '../components/typography/Headline';
import Subtitle from '../components/typography/Subtitle';
import queryString, { ParsedQuery } from 'query-string'
import MoveupContainer from '../components/generalcomponents/MoveupContainer';
import ContainerRow from '../components/generalcomponents/ContainerRow';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import TableComponent from '../components/tables/TableComponent';
import { Container, Row, Col } from 'reactstrap';
import SearchBar from '../components/generalcomponents/SearchBar';
import { DomainService } from '../services/DomainService';
import Instructions from '../components/frontpage/Instructions';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import NewLoadingIndicator from '../components/generalcomponents/NewLoadingIndicator';

interface ComponentProps extends RouteComponentProps<any> {
    query: string,
    history: any
};

type ComponentState = {
    searchQuery: string;
    analysisQuery: string;
    domains: Array<Domain>;
    showClearTextIcon: boolean;
    loading: boolean;
};

interface Domain {
    id: number,
    word: string
};

class ConsultantPage extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        // This adds a debounce everytime method is called
        this.fetchDomainData = isMobile? _.debounce(this.fetchDomainData, 1000) : _.debounce(this.fetchDomainData, 300)
    }
    
    state: ComponentState = {
        searchQuery: '',
        analysisQuery: '',
        domains: [],
        showClearTextIcon: false,
        loading: false
    };

    /**
     * Get query string and
     * fetch relevant items.
     */
    componentDidMount = async (): Promise<void> => {
        window.scrollTo(0, 0);
        const values: ParsedQuery<string> = queryString.parse(this.props.query);

        if (values.ord) {
            const word: string = values.ord.toString();

            this.setState({ 
                searchQuery: word,
                analysisQuery: word,
                showClearTextIcon: true
            });

            this.fetchDomainData(word);
        }

        GoogleAnalyticsService.initGoogleAnalytics();
        GoogleAnalyticsService.trackGoogleAnalyticsPageview();
    }

    /**
     * Fetch domain data by generating lists
     * from the data.
     * 
     * @param {String} text 
     */
    fetchDomainData = async (text: string): Promise<void> => {
        if (text) {
            this.setState({ 
                domains: [],
                loading: true
            });

            let domainArray: Domain[] = await DomainService.getConsultantNames(text);
            let domains: Domain[] = await DomainService.randomizeArray(domainArray);

            this.setState({ 
                domains: domains,
                loading: false
            });
            
            GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Search','Typing', text.toLowerCase());
        }
    }

     /**
     * Clear text in search bar.
     */
    clearText = (): void => {   
        this.setState({
            searchQuery: '',
            analysisQuery: '',
            domains: [],
            showClearTextIcon: false
        });
        this.props.history.push("/konsulent");
    };

    /**
     * Called whenever search query is updated to get data
     * @param {Event} event 
     */
    handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const searchTerm: string = event.target.value;
        const upperCaseWord: string = searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1)

        this.setState({
            searchQuery: upperCaseWord,
            analysisQuery: upperCaseWord
        });

        if (upperCaseWord.length < 1) {
            this.clearText();
          } else {
            this.setState({
              showClearTextIcon: true,
            });
        }

        this.fetchDomainData(upperCaseWord);
        this.props.history.push("/konsulent?ord=" + upperCaseWord);
    }

    render() {
        return(
            <div>
                <div style={headlineWrapper}>
                    <div className="container">
                        <div className="text-center" style={headlinePadding}>
                            <Headline>Navne til konsulenter<Dot/></Headline>
                            <Subtitle text="Se eksempler på gode navne - Prøv dit eget navn" />
                        </div>
                    </div>
                    <ContainerRow centered={true}>
                        <div className="col-md-6 offset-md-3">
                            <SearchBar
                            value = {this.state.searchQuery}
                            placeholder="Søg på et ord.."
                            handleChange = {(event: any) => this.handleChange(event)}
                            clearText = {() => this.clearText()}
                            showClearTextIcon = {this.state.showClearTextIcon}
                            />
                        </div>
                    </ContainerRow>
                </div>

                {this.state.analysisQuery === '' && ( 
                    <Instructions/>
                )}

                {this.state.analysisQuery !== '' && this.state.domains.length === 0 && (
                    <MoveupContainer>
                        <ContainerRow centered={true}>
                            <div className="col-md-4 offset-md-4">
                                <NewLoadingIndicator/>
                            </div>
                        </ContainerRow>
                    </MoveupContainer>
                )}

                {this.state.analysisQuery !== '' && this.state.domains.length > 0 && (
                    <MoveupContainer>
                        <Container>
                            <Row>
                                <Col>
                                    <TableComponent data={this.state.domains} />
                                </Col>
                            </Row>
                        </Container>
                    </MoveupContainer>
                )}
            </div>
        );
    }
}

const headlineWrapper = {
    backgroundColor: Colors.headerBackground,
    paddingTop: 50,
    paddingBottom: 80
} as React.CSSProperties;

const headlinePadding = {
    paddingBottom: 20
} as React.CSSProperties;

export default withRouter(ConsultantPage);