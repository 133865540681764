import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion"
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import Checkmark from '../../assets/checked.png';
import NotCheckmark from '../../assets/not-available.png';

const LetterOtherCard = (props) => {
    const { text } = props;
    const [title, setTitle] = useState('Danske bogstaver');
    const [subtitle, setSubtitle] = useState('Indeholder ikke æ, ø eller å');
    const [iconSource, setIconSource] = useState(Checkmark);
    const firstLetter = 'ö';
    const secondLetter = 'ä';
    const thirdLetter = 'ü';
    const fourthLetter = 'é';

    useEffect(() => {
        if (text.includes(firstLetter) || text.includes(secondLetter) || text.includes(thirdLetter) || text.includes(fourthLetter)) {
            setTitle('Undgå ö, ä, ü og é');
            setSubtitle('De er ikke gode i domæner');
            setIconSource(NotCheckmark);
        } else {
            setTitle('Danske bogstaver');
            setSubtitle('Indeholder ikke ö, ä, ü eller é');
            setIconSource(Checkmark);
        }
    }, [text])

    return (
        <motion.div className="col-lg-4 col-md-6 col-sm-6 col-12" variants={itemStyles}>
            <div style={card} className="card elevation">
                <div className="header">
                    <h3 style={cardTitle}>{title}</h3>
                    <p style={cardSubtitle}>{subtitle}</p>
                    <img style={icon} src={iconSource} alt="analyse-ikon" />
                </div>
            </div>
        </motion.div>
    );
}
  
LetterOtherCard.propTypes = {
    text: PropTypes.string.isRequired
};

const itemStyles = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
};

const card = {
    backgroundColor: Colors.white,
    padding: 30,
    marginBottom: 20,
    borderRadius: 15,
    border: "1px solid #E8EAED"
};

const cardTitle = {
    color: Colors.almostBlack,
    fontWeight: Variables.fontWeightHeadline,
    fontSize: 20,
    lineHeight: 1
};

const cardSubtitle = {
    color: Colors.grey,
    fontWeight: Variables.fontSizeSubtitle,
    fontSize: 15,
    lineHeight: 1,
    marginBottom: 0
};

const icon = {
    position: 'absolute',
    right: 25,
    top: 27
};
  
export default LetterOtherCard;