import React from 'react';
import Dot from '../components/generalcomponents/Dot';
import Colors from '../constants/Colors';
import { GoogleAnalyticsService } from '../services/GoogleAnalyticsService';
import Headline from '../components/typography/Headline';
import Subtitle from '../components/typography/Subtitle';
import queryString, { ParsedQuery } from 'query-string'
import MoveupContainer from '../components/generalcomponents/MoveupContainer';
import ContainerRow from '../components/generalcomponents/ContainerRow';
import LetterCard from '../components/analysiscomponents/LetterCard';
import WordLengthCard from '../components/analysiscomponents/WordLengthCard';
import HyphenCard from '../components/analysiscomponents/HyphenCard';
import DanishDomainCard from '../components/analysiscomponents/DanishDomainCard';
import SynonymCard from '../components/analysiscomponents/SynonymCard';
import DotComDomainCard from '../components/analysiscomponents/DotComDomainCard';
import NumbersDomainCard from '../components/analysiscomponents/NumbersDomainCard';
import InstagramCard from '../components/analysiscomponents/InstagramCard';
import FacebookCard from '../components/analysiscomponents/FacebookCard';
import SectionHeadline from '../components/typography/SectionHeadline';
import TwitterCard from '../components/analysiscomponents/TwitterCard';
import YoutubeCard from '../components/analysiscomponents/YoutubeCard';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import AnalysisSearchBar from '../components/searchbar/AnalysisSearchBar';
import AnalysisInstructions from '../components/analysiscomponents/AnalysisInstructions';
import DomainListClass from '../components/analysiscomponents/DomainListClass';
import CvrList from '../components/analysiscomponents/CvrList';
import LetterOtherCard from '../components/analysiscomponents/LetterOtherCard';
import MotionContainer from '../components/generalcomponents/MotionContainer';

interface ComponentProps extends RouteComponentProps<any> {
    query: string,
    history: any
};

type ComponentState = {
    searchQuery: string;
    analysisQuery: string;
    showClearTextIcon: boolean;
};

class AnalysisPage extends React.Component<ComponentProps, ComponentState> {
    
    state: ComponentState = {
        searchQuery: '',
        analysisQuery: '',
        showClearTextIcon: false
    };

    /**
     * Check URL parameter and search if
     * it is present.
     */
    componentDidMount = async (): Promise<void> => {
        window.scrollTo(0, 0);
        const values: ParsedQuery<string> = queryString.parse(this.props.query);

        if (values.ord) {
            const word: string = values.ord.toString();

            this.setState({ 
                analysisQuery: word,
                searchQuery: word,
                showClearTextIcon: true
            });
        }

        GoogleAnalyticsService.initGoogleAnalytics();
        GoogleAnalyticsService.trackGoogleAnalyticsPageview();
    }

    /**
     * Handles a click on a tag in
     * the synonym component.
     * @param {string} text 
     */
    tagClick = async (text: string): Promise<void> => {
        if (text) {
            this.setState({ 
                analysisQuery: text,
                searchQuery: text
            });
        };
        this.props.history.push("/analyse?ord=" + text);    
    }

    /**
     * Called whenever search query is updated to get data
     * @param {Event} event 
     */
    handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        this.setState({
            searchQuery: event.target.value
        });

        if (event.target.value.length < 1) {
            this.clearText();
          } else {
            this.setState({
              showClearTextIcon: true,
            });
        }
    }

    /**
     * Submit search bar.
     * @param {Event} event 
     */
    handleSubmit = (event: React.ChangeEvent<HTMLInputElement>): void => {   
        event.preventDefault();
        this.setState({ analysisQuery: this.state.searchQuery });
        this.props.history.push("/analyse?ord=" + this.state.searchQuery);
    };

    /**
     * Clear text in search bar.
     */
    clearText = (): void => {   
        this.setState({
            analysisQuery: '',
            searchQuery: '',
            showClearTextIcon: false
        });
        this.props.history.push("/analyse");
    };

    render() {
        return(
            <div>
                <div style={headlineWrapper}>
                    <div className="container">
                        <div className="text-center" style={headlinePadding}>
                            <Headline>Analyse af et firmanavn<Dot/></Headline>
                            <Subtitle text="Check om dit navn er ledigt og godt" />
                        </div>
                    </div>
                    <ContainerRow centered={true}>
                        <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                            <AnalysisSearchBar
                            value = {this.state.searchQuery}
                            placeholder="Søg på et ord.."
                            handleSubmit = {(event) => this.handleSubmit(event)}
                            handleChange = {(event: any) => this.handleChange(event)}
                            />
                        </div>
                    </ContainerRow>
                </div>

                {this.state.analysisQuery === '' && ( 
                    <div> 
                        <br></br>
                        <AnalysisInstructions/>
                    </div>
                )}

                {this.state.analysisQuery !== '' && (
                    <div>
                        <MotionContainer>
                            <MoveupContainer>
                                <ContainerRow>
                                    <DanishDomainCard text={this.state.analysisQuery} />
                                    <DotComDomainCard text={this.state.analysisQuery} />
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <SectionHeadline showDot={true}>
                                            Ordet
                                        </SectionHeadline>
                                    </div>
                                    <LetterCard text={this.state.analysisQuery} />
                                    <WordLengthCard text={this.state.analysisQuery} />
                                    <NumbersDomainCard text={this.state.analysisQuery} />
                                    <HyphenCard text={this.state.analysisQuery} />
                                    <LetterOtherCard text={this.state.analysisQuery} />
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <SynonymCard text={this.state.analysisQuery} onTagClick={this.tagClick} />
                                    </div>
                                </ContainerRow>
                                <br></br>
                                <ContainerRow>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <SectionHeadline showDot={true}>
                                            CVR Data
                                        </SectionHeadline>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <CvrList text={this.state.analysisQuery} />
                                    </div>
                                </ContainerRow>
                                <br></br>
                                <ContainerRow>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <SectionHeadline showDot={true}>
                                            Sociale Medier
                                        </SectionHeadline>
                                    </div>
                                    <FacebookCard text={this.state.analysisQuery} />
                                    <InstagramCard text={this.state.analysisQuery} />
                                    <TwitterCard text={this.state.analysisQuery} />
                                    <YoutubeCard text={this.state.analysisQuery} />
                                </ContainerRow>
                                <br></br>
                                <ContainerRow>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <SectionHeadline showDot={true}>
                                            Andre domæner
                                        </SectionHeadline>
                                    </div>
                                    <DomainListClass text={this.state.analysisQuery} />
                                </ContainerRow>
                            </MoveupContainer>
                        </MotionContainer>
                    </div>
                )}
            </div>
        );
    }
}

const headlineWrapper = {
    backgroundColor: Colors.headerBackground,
    paddingTop: 50,
    paddingBottom: 80
} as React.CSSProperties;

const headlinePadding = {
    paddingBottom: 20
} as React.CSSProperties;

export default withRouter(AnalysisPage);