import React from 'react';
import PropTypes from 'prop-types'; 
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';

const AnalysisTag = (props) => {
    return (
        <div onClick={props.onPress}>
            <p style={textStyle}>{props.text}</p>
        </div>
    );
}

AnalysisTag.propTypes = {
    onPress: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

const textStyle = {
    fontFamily: Variables.fontFamilyHeadline,
    color: Colors.almostBlack,
    fontSize: 16,
    backgroundColor: Colors.lightThemeColor,
    paddingRight: 12,
    paddingLeft: 12,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 5,
    cursor: 'pointer',
};

export default AnalysisTag;