import React from 'react';

interface IconComponentProps {
  src: string,
  alt: string,
  right?: number,
  top?: number
}

const IconComponent: React.FunctionComponent<IconComponentProps> = ({ src, alt, right, top }) => {
  
  const iconStyles = {
    position: 'absolute',
    right: right,
    top: top
  } as React.CSSProperties;

  return(
    <img style={iconStyles} src={src} alt={alt} />
  );
}

IconComponent.defaultProps = {
  right: 32,
  top: 23
} as Partial<IconComponentProps>;

export default IconComponent;