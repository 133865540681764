import React, { useEffect, useState } from 'react';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import { useCookies } from 'react-cookie';
import { FiX } from "react-icons/fi";
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';
import { isMobile } from 'react-device-detect';

interface ComponentProps {
  title: string,
  subtitle: string,
  imageSource: string,
  link: string,
  daysToLive?: number,
  secondsBeforeBannerShows?: number
};

const CookieBanner: React.FunctionComponent<ComponentProps> = ({ title, subtitle, imageSource, link, daysToLive, secondsBeforeBannerShows }) => {
  const [cookies, setCookie] = useCookies(['banner']);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [showAnimation, setShowAnimation] = useState<boolean>(true);

  let today: Date = new Date();
  let expirationDate: Date = new Date();
  expirationDate.setDate(today.getDate() + (daysToLive !== undefined ? daysToLive : 5));
  const secondsBeforeBanner = (secondsBeforeBannerShows !== undefined ? secondsBeforeBannerShows * 1000 : 7 * 1000);

  useEffect(() => {

    const createBannerCookie = (): void => {
      setTimeout(() => {
        if (cookies.banner !== 'shown' && showBanner === false) {
          setShowBanner(true);
          setCookie('banner', 'shown', {
            expires: expirationDate
          });
        }
      }, secondsBeforeBanner);
    };

    createBannerCookie();
  }, [cookies.banner, expirationDate, secondsBeforeBanner, setCookie, showBanner])

  const openLink = (): void => {
    window.open(link, "_blank");
    GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Banner','Click', 'Banner');
  };

  const fadeOut = (): void => {
    setShowAnimation(false);

    setTimeout(() => {
        setShowBanner(false);
    }, 2000);
  };

  return(
    <>
    {showBanner && ( 
      <div style={bannerCard} className={showAnimation ? 'fadein' : 'fadeout'}>
        <img onClick={openLink} style={imageStyle} src={imageSource} alt="Banner" />
        <div onClick={openLink} style={textWrapper}>
          <h3 style={titleStyle}>{title}</h3>
          <p style={subtitleStyle}>{subtitle}</p>
        </div>
        <FiX style={closeIcon} onClick={fadeOut} />
      </div>
    )}
    </>
  );
}

CookieBanner.defaultProps = {
  secondsBeforeBannerShows: 5,
  daysToLive: 7
} as Partial<ComponentProps>;

const bannerCard = {
  position: 'fixed',
  left: (isMobile ? 0 : 30),
  bottom: (isMobile ? 0 : 30),
  width: (isMobile ? '100%' : 'auto'),
  maxWidth: 440,
  zIndex: 2147483647,
  display: 'flex',
  justifyContent: 'left',
  padding: 12,
  fontFamily: Variables.fontFamilyText,
  borderRadius: 6,
  backgroundColor: Colors.white,
  boxShadow: '0 5px 20px rgba(0, 0, 0, 0.15)',
  cursor: 'pointer'
} as React.CSSProperties;

const textWrapper = {
  display: 'flex',
  paddingLeft: 10,
  flexDirection: 'column',
  cursor: 'pointer'
} as React.CSSProperties;

const titleStyle = {
  color: '#404447',
  fontWeight: 'bold',
  fontSize: 17,
  lineHeight: 1.25,
  marginBottom: 5,
  cursor: 'pointer'
} as React.CSSProperties;

const imageStyle = {
  height: 68,
  width: 68,
  minWidth: 68
} as React.CSSProperties;

const subtitleStyle = {
  color: '#A0A6AC',
  fontSize: 14,
  lineHeight: 1.4,
  margin: 0,
  marginBottom: 2,
  cursor: 'pointer',
  wordBreak: 'normal',
  hyphens: 'auto'
} as React.CSSProperties;

const closeIcon = {
  position: 'absolute',
  right: 5,
  top: 5,
  padding: 5,
  width: 30,
  height: 30,
  zIndex: 2147483649
} as React.CSSProperties;

export default CookieBanner;