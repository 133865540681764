import React, { useState } from 'react';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';

interface FilterLinkProps {
  text: string,
  fontSize?: number,
  onClick: Function
}

const FilterLink: React.FunctionComponent<FilterLinkProps> = ({ text, fontSize, onClick }) => {
  const [hover, setHover] = useState(false);

  const textStyle = {
      color: Colors.almostBlack,
      opacity: 0.8,
      fontFamily: Variables.fontFamilyText,
      fontSize: fontSize,
      lineHeight: 1.3,
      marginBottom: 5,
      cursor: 'pointer',
      padding: 4
  };

  const hoverStyle = {
      color: Colors.white,
      opacity: 0.8,
      fontFamily: Variables.fontFamilyText,
      fontSize: fontSize,
      lineHeight: 1.3,
      marginBottom: 5,
      cursor: 'pointer',
      backgroundColor: Colors.themeColor,
      borderRadius: 5,
      padding: 4
  };

  return (
      <p 
      style={hover ? hoverStyle : textStyle} 
      onMouseEnter={()=>{
        setHover(true);
      }}
      onMouseLeave={()=>{
        setHover(false);
      }}
      onClick={() => onClick()}
      >
          {text}
      </p>
  );
}

FilterLink.defaultProps = {
  fontSize: 20
} as Partial<FilterLinkProps>;

export default FilterLink;