import React from 'react'
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import { FaSync } from 'react-icons/fa';

interface ComponentProps {
    onClick: (event: React.MouseEvent<HTMLElement>) => void,
    loadingMore?: boolean
};

const LoadMoreButton: React.FunctionComponent<ComponentProps> = ({ onClick, loadingMore }) => {
    return(
        <div className="col-md-6 offset-md-3 text-center">
            <button onClick={onClick} style={defaultStyle}>
                Hent flere
                <FaSync 
                className={(loadingMore ? 'icon-spin' : '')}
                style={icon}
                size={16}
                />
            </button>
        </div>
    );
}

const defaultStyle = {
    color: Colors.white,
    backgroundColor: Colors.themeColor,
    fontFamily: Variables.fontFamilyHeadline,
    fontSize: 14,
    paddingRight: 14,
    paddingLeft: 14,
    paddingTop: 14,
    paddingBottom: 14,
    borderRadius: 10,
    outline: 'none',
} as React.CSSProperties;

const icon = {
    color: Colors.lightThemeColor,
    marginLeft: 10,
} as React.CSSProperties;

export default LoadMoreButton;