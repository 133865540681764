import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkmark from '../../assets/checked.png';
import NotCheckmark from '../../assets/not-available.png';
import MotionRow from '../generalcomponents/MotionRow';
import Card from '../cards/Card';
import CardTitle from '../typography/CardTitle';
import CardSubtitle from '../typography/CardSubtitle';
import IconComponent from '../icons/IconComponent';

const LetterCard = (props) => {
    const { text } = props;
    const [title, setTitle] = useState('Gode bogstaver');
    const [subtitle, setSubtitle] = useState('Indeholder ikke æ, ø eller å');
    const [iconSource, setIconSource] = useState(Checkmark);
    const firstLetter = 'æ';
    const secondLetter = 'ø';
    const thirdLetter = 'å';

    useEffect(() => {
        if (text.includes(firstLetter) || text.includes(secondLetter) || text.includes(thirdLetter)) {
            setTitle('Undgå æ, ø og å');
            setSubtitle('De er ikke gode i domæner');
            setIconSource(NotCheckmark);
        }
    }, [text])

    return (
      <MotionRow lg="6">
        <Card padding={30}>
          <CardTitle>{title}</CardTitle>
          <CardSubtitle>{subtitle}</CardSubtitle>
          <IconComponent right={40} top={30} src={iconSource} alt="analyse-ikon" />
        </Card>
      </MotionRow>
    );
}
  
LetterCard.propTypes = {
  text: PropTypes.string.isRequired
};

export default LetterCard;