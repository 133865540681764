import React from 'react';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import Domains from '../../assets/domains.svg';
import Analysis from '../../assets/analysis.svg';
import Social from '../../assets/social.svg';

const AnalysisInstructions = () => {
    return(
        <div style={instructionsContainer}>
            <div className="container">
                <div className="col-md-10 offset-md-1 text-center">
                    <div style={liftCards} className="row">
                        <div className="col-md-4">
                            <div className="instruction-card">
                                <h3 style={instructionsHeader}>Check domæner</h3>
                                <img style={image} src={Domains} alt="Find domæne" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="instruction-card">
                                <h3 style={instructionsHeader}>Analyse af ordet</h3>
                                <img style={image} src={Analysis} alt="Analyse af ord" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="instruction-card">
                                <h3 style={instructionsHeader}>Sociale Medier</h3>
                                <img style={image} src={Social} alt="Sociale Medier" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const instructionsContainer = {
    backgroundColor: Colors.lightThemeColor,
    paddingTop: 15
}

const liftCards = {
    position: 'relative',
    bottom: 80
}

const instructionsHeader = {
    fontSize: 15,
    fontFamily: Variables.fontFamilyHeadline,
    fontWeight: Variables.fontWeightHeadline,
    color: Colors.themeColor,
    textTransform: 'uppercase',
    paddingBottom: 10
}

const image = {
    width: '100%',
    height: 100
}

export default AnalysisInstructions;