import React, { useState, useEffect } from 'react';
import Colors from '../../constants/Colors';
import Checkmark from '../../assets/checked.png';
import NotAvailable from '../../assets/not-available.png';
import { ApiService } from '../../services/ApiService';
import Loader from '../../assets/rings-spinner.svg';
import ApiEndpoints from '../../constants/ApiEndpoints';
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';
import MotionRow from '../generalcomponents/MotionRow';
import Card from './Card';
import CardTitle from '../typography/CardTitle';
import DomainTag from '../tags/DomainTag';

interface ComponentProps {
    text: string
};

const SimpleDanishDomainCard: React.FunctionComponent<ComponentProps> = ({ text }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [title, setTitle] = useState<string>(text);
    const [domainAvailable, setDomainAvailable] = useState<boolean>(false);
    const domainLink: string = ApiEndpoints.affiliateBaseUrl + ApiEndpoints.domainBaseUrl + text;
    const klagenLink: string = 'https://www.klagen.dk/'

    /**
     * The effect hook fetching data
     * for the component.
     */
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            await timer(1000);
            if (text !== null && text !== '') {
                setIsLoading(true);
                setTitle(text);
                const domainAvailability: boolean | undefined = await ApiService.isDomainAvailable(text);
                (domainAvailability) ? setDomainAvailable(domainAvailability) : setDomainAvailable(false);
                setIsLoading(false);
            }
        }
        fetchData();
    }, [text])

    /**
     * A delay timer to hold
     * the API in the view.
     * 
     * @param {number} ms 
     */
    function timer(ms: number): Promise<any> {
        return new Promise(res => setTimeout(res, ms));
    }

    /**
     * Triggers an event sent to Google Analytics with
     * the domain click.
     */
    const handleClick = (): void => {
        GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Domain', 'Click', text)
    }

    return (
        <MotionRow>
            {isLoading && (
                <a href={domainLink} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                    <Card>
                        <div>
                            <CardTitle>{title}.dk</CardTitle>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <DomainTag text="Checker domæner..." />
                                </li>
                            </ul>
                            <img style={loadingIndicator} src={Loader} alt="loading-ikon" />
                        </div>
                    </Card>
                </a>
            )}
            {!isLoading && domainAvailable && domainAvailable !== null && (
                <a href={domainLink} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                    <Card>
                        <div>
                            <CardTitle>{title}.dk</CardTitle>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <DomainTag text="Gå til domæne →" />
                                </li>
                            </ul>
                            <img style={icon} src={Checkmark} alt="analyse-ikon" />
                        </div>
                    </Card>
                </a>
            )}
            {!isLoading && !domainAvailable && !domainAvailable !== null && (
                <a href={klagenLink} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                    <Card>
                        <div>
                            <CardTitle>{title}.dk</CardTitle>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <DomainTag text="Se mulighed for overtagelse" />
                                </li>
                            </ul>
                            <img style={icon} src={NotAvailable} alt="analyse-ikon" />
                        </div>
                    </Card>
                </a>
            )}

        </MotionRow>
    );
}

const icon = {
    position: 'absolute',
    right: 30,
    top: 24
} as React.CSSProperties;

const loadingIndicator = {
    width: 50,
    height: 50,
    color: Colors.red,
    position: 'absolute',
    right: 30,
    top: 20
} as React.CSSProperties;

export default SimpleDanishDomainCard;