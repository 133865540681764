import React from 'react';
import Card from './Card';
import CardTitle from '../typography/CardTitle';
import CardSubtitle from '../typography/CardSubtitle';
import IconComponent from '../icons/IconComponent';

interface ComponentProps {
  title: string,
  tagline: string,
  imageSource: string,
  link: string
};

const WebsiteCard: React.FunctionComponent<ComponentProps> = ({ title, tagline, imageSource, link }) => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
          <Card>
              <CardTitle fontSize={20}>{title}</CardTitle>
              <CardSubtitle fontSize={15}>{tagline}</CardSubtitle>
              <IconComponent src={imageSource} alt="website" right={32} top={20} /> 
          </Card>
      </a>
    );
}

export default WebsiteCard;