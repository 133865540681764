import React from 'react';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';

interface SubtitleProps {
    text: string,
    fontSize?: number
}

const Subtitle: React.FunctionComponent<SubtitleProps> = ({ text, fontSize }) => {

    const textStyle = {
        color: Colors.subtitleColor,
        opacity: 0.8,
        fontFamily: Variables.fontFamilyText,
        fontSize: fontSize,
        lineHeight: 1.3,
        marginBottom: 5
    };

    return (
        <p style={textStyle}>{text}</p>
    );
}

Subtitle.defaultProps = {
    fontSize: Variables.fontSizeSubtitle
  } as Partial<SubtitleProps>;

export default Subtitle;