import { ApiService } from "./ApiService";

/**
 * Interface for the domain.
 */
interface Domain {
    id: number,
    word: string
}

/**
 * Create the full list of domains
 * to loop over and show on the main page.
 * 
 * @param {String} domain 
 */
const createDomainList = async (domain: string): Promise<Array<Domain>> => {
    const prefixWords: Array<Domain> = await ApiService.getPrefixWords();
    const suffixWords: Array<Domain> = await ApiService.getSuffixWords();
    let potentialDomain: string = '';
    let domainArray: Array<Domain> = [];
    let loopId: number = 1;

    for (let index = 0; index < prefixWords.length; index++) {
        potentialDomain = prefixWords[index].word + domain.toLowerCase();
        
        let domainPostObject: Domain = {
            "id": index,
            "word": potentialDomain
        }

        domainArray.push(domainPostObject);
        loopId++;
    }
    
    for (let index = 0; index < suffixWords.length; index++) {
        potentialDomain = domain.toLowerCase() + suffixWords[index].word;
        
        let domainPreObject: Domain = {
            "id": loopId,
            "word": potentialDomain
        }

        domainArray.push(domainPreObject);
        loopId++;
    } 

    return domainArray;
}

/**
 * Interface for the domains
 * used on analysis page.
 */
interface AnalysisDomain {
    id: number,
    word: string,
    availability: boolean
}

/**
 * Create the analysis list of domains
 * to loop over and show on the analysis page.
 * 
 * @param {String} domain 
 */
const createAnalysisDomainList = async (domain: string): Promise<Array<AnalysisDomain>> => {
    const prefixWords: Array<Domain> = await ApiService.getAnalysisPrefix();
    const suffixWords: Array<Domain> = await ApiService.getAnalysisSuffix();
    let potentialDomain: string = '';
    let domainArray: Array<AnalysisDomain> = [];
    let loopId: number = 1;

    for (let index = 0; index < prefixWords.length; index++) {
        potentialDomain = prefixWords[index].word + domain.toLowerCase();
        
        let domainPostObject: AnalysisDomain = {
            "id": index,
            "word": potentialDomain,
            "availability": true
        }

        domainArray.push(domainPostObject);
        loopId++;
    }
    
    for (let index = 0; index < suffixWords.length; index++) {
        potentialDomain = domain.toLowerCase() + suffixWords[index].word;
        
        let domainPreObject: AnalysisDomain = {
            "id": loopId,
            "word": potentialDomain,
            "availability": true
        }

        domainArray.push(domainPreObject);
        loopId++;
    } 

    return domainArray;
}

/**
 * Get all names for the consultant page.
 * @param domain 
 */
const getConsultantNames = async (domain: string): Promise<Array<Domain>> => {
    const prefixWords: Array<Domain> = await ApiService.getConsultantPrefix();
    const suffixWords: Array<Domain> = await ApiService.getConsultantSuffix();
    let potentialDomain: string = '';
    let domainArray: Array<Domain> = [];
    let loopId: number = 1;

    for (let index = 0; index < prefixWords.length; index++) {
        potentialDomain = prefixWords[index].word + domain;
        
        let domainPostObject: Domain = {
            "id": index,
            "word": potentialDomain
        }

        domainArray.push(domainPostObject);
        loopId++;
    }
    
    for (let index = 0; index < suffixWords.length; index++) {
        potentialDomain = domain + suffixWords[index].word;
        
        let domainPreObject: Domain = {
            "id": loopId,
            "word": potentialDomain
        }

        domainArray.push(domainPreObject);
        loopId++;
    } 

    return domainArray;
}

/**
 * Gets data and returns the number
 * of domains available.
 */
const getNumberOfDomains = async (): Promise<number> => {
    const prefixWords: Array<Domain> = await ApiService.getPrefixWords();
    const suffixWords: Array<Domain> = await ApiService.getSuffixWords();
    const fullArray: Array<Domain> = prefixWords.concat(suffixWords);

    return fullArray.length;
}

/**
 * Randomizes an array
 * @param {Array} array 
 */
const randomizeArray = async (array: Array<any>): Promise<Array<any>> => {
    let currentIndex: number = array.length
    let temporaryValue: number;
    let randomIndex: number;
  
    while (0 !== currentIndex) {
  
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
}

export const DomainService = {
    createDomainList,
    createAnalysisDomainList,
    getConsultantNames,
    getNumberOfDomains,
    randomizeArray
}