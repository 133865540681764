import React from 'react';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';

interface HeadlineProps {
    children: any,
    fontSize?: number
}

const Headline: React.FunctionComponent<HeadlineProps> = ({ children, fontSize }) => {
    
    const textStyle = {
        color: Colors.headlineColor,
        fontFamily: Variables.fontFamilyHeadline,
        fontWeight: Variables.fontWeightHeadline,
        fontSize: fontSize,
        lineHeight: 0.7,
        marginBottom: 20
    };

    return (
        <h1 style={textStyle}>
            {children}
        </h1>
    );
}

Headline.defaultProps = {
    fontSize: Variables.fontSizeHeadline
  } as Partial<HeadlineProps>;

export default Headline;