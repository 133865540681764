import React from 'react';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import { motion } from "framer-motion"
import { useAsync } from 'react-async-hook';
import shortid from 'shortid';
import AnalysisTag from './AnalysisTag';
import Icon from '../../assets/document.png';

const fetchData = async word => word !== '' ? (await fetch("https://firma-navn-functions.azurewebsites.net/api/FetchSynonyms?text=" + word + "&code=ssrB7hyaROPyPSbbRp0hoDVrgVtf8WwLUGcGrTFXbS4MaqKJDRAooA==")).json() : console.log("loading");          

const SynonymCard = (props) => {
    const title = "Synonymer";
    const subtitle = "Alternative ord til dit domæne"
    const numberOfSynonmys = 20;
    const asyncHero = useAsync(fetchData, [props.text]);
    
    /**
     * Shuffle an array.
     * 
     * @param {Array} a 
     */
    const shuffle = (a) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    return (
        <motion.div variants={itemStyles}>           
                  {asyncHero.loading && <div>Loading</div>}
                  {asyncHero.result && (
                    <div style={card} className="card elevation">
                      <div className="header">   
                        <h3 style={cardTitle}>{title}</h3>
                        <p style={cardSubtitle}>{subtitle}</p>      
                        <ul className="list-inline">                        
                        {shuffle(asyncHero.result).slice(0, numberOfSynonmys).map((a, b) =>
                            <li className="list-inline-item" key={shortid.generate()}><AnalysisTag text={asyncHero.result[b]} onPress={() => props.onTagClick(asyncHero.result[b])} /></li>
                        )}
                        </ul>
                      </div>
                    </div>
                  )}
                  {!asyncHero.result && !asyncHero.loading && (
                    <div style={card} className="card elevation text-center">
                      <div className="header">
                          <img style={icon} src={Icon} alt="dokument" />
                          <h3 style={cardTitle}>Vi fandt ingen synonymer</h3>
                          <p style={cardSubtitle}>Søg efter et andet navn</p>
                      </div>
                    </div>
                )}               
        </motion.div>
    );
}

  
const itemStyles = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    },
    key:shortid.generate()
};
  
const card = {
  backgroundColor: Colors.white,
  paddingLeft: 30,
  paddingTop: 50,
  paddingBottom: 50,
  paddingRight: 30,
  marginBottom: 20,
  borderRadius: 15,
  border: "1px solid #E8EAED",
};

const cardTitle = {
  color: Colors.almostBlack,
  fontWeight: Variables.fontWeightHeadline,
  fontSize: 25,
  lineHeight: 1,
};

const cardSubtitle = {
  color: Colors.grey,
  fontWeight: Variables.fontSizeSubtitle,
  fontSize: 20,
  lineHeight: 1,
  marginBottom: 15
};

const icon = {
  paddingBottom: 10
};

export default SynonymCard;