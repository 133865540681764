import React from 'react';
import Colors from '../../constants/Colors';

interface CardProps {
  children: any,
  padding?: number,
  marginBottom?: number,
  borderRadius?: number
}

const Card: React.FunctionComponent<CardProps> = ({ children, padding, marginBottom, borderRadius }) => {
   
  const cardStyling = {
    backgroundColor: Colors.white,
    padding: padding,
    marginBottom: marginBottom,
    borderRadius: borderRadius,
    border: "1px solid #E8EAED",
  } as React.CSSProperties;

  return(
      <div style={cardStyling}>
          {children}
      </div>
  );
}

Card.defaultProps = {
  padding: 20,
  marginBottom: 20,
  borderRadius: 15
} as Partial<CardProps>;

export default Card;