import React from 'react';
import Variables from '../../constants/Variables';
import Colors from '../../constants/Colors';

interface ComponentProps {
  text: string | string[],
  onPress: (event: React.MouseEvent<HTMLElement>) => void,
  paddingLeft?: number,
  paddingRight?: number
}

const Tag: React.FunctionComponent<ComponentProps> = ({ text, onPress, paddingLeft, paddingRight }) => {
  
  const textStyle = {
      fontFamily: Variables.fontFamilyHeadline,
      color: Colors.almostBlack,
      fontSize: 12,
      backgroundColor: Colors.lightThemeColor,
      paddingRight: paddingRight,
      paddingLeft: paddingLeft,
      paddingTop: 4,
      paddingBottom: 4,
      marginTop: 10,
      borderRadius: 5,
      cursor: 'pointer'
  } as React.CSSProperties;

  return(
    <div onClick={onPress}>
        <p style={textStyle}>{text}</p>
    </div>
  );
}

Tag.defaultProps = {
  paddingLeft: 8,
  paddingRight: 8
} as Partial<ComponentProps>;

export default Tag;