import React from 'react'
import Colors from '../../constants/Colors';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaTwitterSquare } from 'react-icons/fa';
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';

const SocialComponent: React.FunctionComponent = () => {

    const shareOnFacebook = (): void => {
        let url: string = 'https://www.facebook.com/sharer/sharer.php?u=https://firma-navn.dk&t=Find et ledigt virksomhedsnavn"';
        window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
        GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Social','Click','Facebook')
    }
    
    const shareOnTwitter = (): void => {
        let url: string = 'https://twitter.com/intent/tweet?url=https://www.firma-navn.dk/';
        window.open(url);
        GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Social','Click','Twitter')
    }

    return(
        <div style={container} className="col-md-6 offset-md-3 text-center">
            <ul className="list-inline">
                <li className="list-inline-item" onClick={shareOnFacebook}>
                    <FaFacebookSquare 
                    style={icon}
                    size={35}
                    />
                </li>
                <li className="list-inline-item" onClick={shareOnTwitter}>
                    <FaTwitterSquare 
                    style={icon}
                    size={35}
                    />
                </li>
            </ul>
        </div>
    );
}

const container = {
    paddingBottom: 10
} as React.CSSProperties;

const icon = {
    color: Colors.themeColor,
    margin: 2,
    cursor: 'pointer'
} as React.CSSProperties;

export default SocialComponent;