import ReactGA from 'react-ga';

/**
 * Initialize Google Analytics tracking
 */
const initGoogleAnalytics = (): void => {
    ReactGA.initialize('UA-141825441-1');
}

/**
 * Track a pageview based on the url
 */
const trackGoogleAnalyticsPageview = (): void => {
    ReactGA.pageview(window.location.pathname);
}

/**
 * Trigger event in Google Analytics
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
const triggerGoogleAnalyticsEventLabel = (category: string, action: string, label: string): void => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
      });
}

/**
 * Trigger event with value in Google Analytics
 * @param {string} category 
 * @param {string} action 
 * @param {number} value 
 */
const triggerGoogleAnalyticsEventValue = (category: string, action: string, value: number): void => {
    ReactGA.event({
        category: category,
        action: action,
        value: value
      });
}

/**
 * Trigger event in Google Analytics
 * @param {string} category 
 * @param {string} action 
 */
const triggerGoogleAnalyticsEvent = (category: string, action: string): void => {
    ReactGA.event({
        category: category,
        action: action,
      });
}

export const GoogleAnalyticsService = {
    initGoogleAnalytics,
    trackGoogleAnalyticsPageview,
    triggerGoogleAnalyticsEventLabel,
    triggerGoogleAnalyticsEventValue,
    triggerGoogleAnalyticsEvent
}