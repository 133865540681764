import React from 'react';
import loader from '../../assets/loader.svg';
import Colors from '../../constants/Colors';
import Card from '../cards/Card';

interface DomainLoadingIndicatorProps {
    width?: number,
    height?: number
};

const DomainLoadingIndicator: React.FunctionComponent<DomainLoadingIndicatorProps> = ({ width, height }) => {

    const loadingIndicator = {
        marginTop: 10,
        width: width,
        height: height,
        color: Colors.red
    };

    return(
        <div style={loadingWrapper}>
            <Card>
                <img src={loader} style={loadingIndicator} alt="Loader" />
            </Card>
        </div>
    );
}

const loadingWrapper = {
    marginTop: 50,
    marginBottom: 300
} as React.CSSProperties;

DomainLoadingIndicator.defaultProps = {
    width: 100,
    height: 100
} as Partial<DomainLoadingIndicatorProps>;


export default DomainLoadingIndicator;