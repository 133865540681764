import React from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion"
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import HouseIcon from '../../assets/home.png';

const CvrListCard = (props) => {
    const { item } = props;

    return (
      <motion.div className="col-lg-6 col-md-6 col-sm-6 col-12" variants={itemStyles}>
        <a href={item.companylink} target="_blank" rel="noopener noreferrer">
          <div style={card} className="card elevation">
              <div className="header">
                  <h3 style={cardTitle}>{item.companyname}</h3>
                  <p style={cardSubtitle}>CVR Nummer: {item.cvrnumber}</p>
                  <img style={icon} src={HouseIcon} alt="virksomhed" />
              </div>
          </div>
        </a>
      </motion.div>
    );
}
  
CvrListCard.propTypes = {
  item: PropTypes.object.isRequired
};

const itemStyles = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const card = {
  backgroundColor: Colors.white,
  paddingLeft: 30,
  paddingTop: 30,
  paddingBottom: 30,
  paddingRight: 80,
  marginBottom: 20,
  borderRadius: 15,
  border: "1px solid #E8EAED",
};

const cardTitle = {
  color: Colors.almostBlack,
  fontWeight: Variables.fontWeightHeadline,
  fontSize: 20,
  lineHeight: 1,
};

const cardSubtitle = {
  color: Colors.grey,
  fontWeight: Variables.fontSizeSubtitle,
  fontSize: 15,
  lineHeight: 1,
  marginBottom: 0,
};

const icon = {
  position: 'absolute',
  right: 25,
  top: 27,
};

export default CvrListCard;