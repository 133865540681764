import React from 'react';
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';
import ApiEndpoints from '../../constants/ApiEndpoints';
import CardTitle from '../typography/CardTitle';
import MotionRow from '../generalcomponents/MotionRow';
import DomainTag from '../tags/DomainTag';
import Card from './Card';
import IconComponent from '../icons/IconComponent';
import { useHistory } from 'react-router-dom';
import AnalysisButton from '../tags/AnalysisButton';

interface Domain {
  word: string
};

interface ComponentProps {
  item: Domain,
  imageSource: string
};

const HoverCard: React.FunctionComponent<ComponentProps> = ({ item, imageSource }) => {
  let history = useHistory();
  const domain = ApiEndpoints.affiliateBaseUrl + ApiEndpoints.domainBaseUrl + item.word;

  const handleClick = () => {
    GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Domain','Click', item.word)
  };

  return(
    <MotionRow lg="4" md="6" sm="6">
        <Card padding={20} marginBottom={20} borderRadius={15}>
          <a href={domain} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
            <CardTitle fontSize={20}>{item.word}</CardTitle>
            <IconComponent src={imageSource} alt="checkmark" right={32} top={26} /> 
          </a>
          <ul className="list-inline">
              <li className="list-inline-item">
                <a href={domain} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                  <DomainTag text="Se domæne" />
                </a>
              </li>
              <li className="list-inline-item">
                <AnalysisButton text="Se analyse" onPress={()=> history.push('/analyse?ord=' + item.word)} />
              </li>
          </ul>
        </Card>
    </MotionRow>
  );
}

export default HoverCard;