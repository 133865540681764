import React from 'react';
import Colors from '../../constants/Colors';
import { FaSearch } from 'react-icons/fa';
import { TiDelete } from 'react-icons/ti';

interface ComponentProps {
    value: string,
    placeholder: string,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    showClearTextIcon: boolean,
    clearText: () => void
  };
  
const SearchBar: React.FunctionComponent<ComponentProps> = ({ value, placeholder, handleChange, showClearTextIcon, clearText }) => {

    /**
     * Stop page from refreshing on form submission
     * @param {Event} event
     */
    const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>): void => {   
        event.preventDefault();
    }
  
    return(
        <div style={searchContainer}>
            <form className="shadow" onSubmit={(event: any) => handleSubmit(event)}>
                <FaSearch 
                    style={searchIcon}
                    size={25}
                />
                <input
                type="text"
                value={value}
                placeholder={placeholder}
                style={textInput}
                onChange={handleChange}
                />
                {showClearTextIcon && (
                    <TiDelete 
                    style={deleteIcon}
                    size={25}
                    onClick={clearText}
                    />
                )}
            </form>
        </div>
    );
}

SearchBar.defaultProps = {
    search: 'Search...'
  } as Partial<ComponentProps>;

const textInput = {
    boxSizing: 'border-box',
	fontSize: 18,
	background: Colors.white,
	color: Colors.grey,
    paddingTop: 13,
    paddingBottom: 15,
    paddingRight: 13,
    paddingLeft: 50,
    border: 'none',
    outline: 'none',
	width: '100%',
	fontWeight: 'normal',
    borderRadius: 5,
} as React.CSSProperties;

const searchContainer = {
    backgroundColor: Colors.themeColor,
    paddingBottom: 15,
    borderRadius: 5
} as React.CSSProperties;

const searchIcon = {
    color: Colors.lightGrey,
    position: 'absolute',
    left: 30,
    top: 15,
} as React.CSSProperties;

const deleteIcon = {
    color: Colors.lightGrey,
    position: 'absolute',
    right: 30,
    top: 15,
    cursor: 'pointer'
} as React.CSSProperties;

export default SearchBar;