export default {
  
    // Properties for shadow effect
    shadowOpacity: 0.80,
    shadowOpacityMiddle: 0.25,
    shadowOpacityLittle: 0.10,
  
    // Typography variables
    fontFamilyHeadline: 'Poppins',
    fontWeightHeadline: 800,
    fontFamilyText: 'Open Sans',

    // Headline
    fontSizeHeadline: 40,

    // Subheader
    fontSizeSubtitle: 20,
  
    // Navigation variables
    fontSizeNavigation: 18,
    lineHeightNavigation: 50,
    zIndexNavigation: 1,
    fontWeightNavigation: 'bold',
    textTransformNavigation: 'uppercase',
    headerHeight: 50,
    headerPaddingBottom: 25,
  };