import React from 'react';
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';
import ApiEndpoints from '../../constants/ApiEndpoints';
import DomainIcon from '../../assets/checked.png';
import Card from '../cards/Card';
import MotionRow from '../generalcomponents/MotionRow';
import IconComponent from '../icons/IconComponent';
import CardSubtitle from '../typography/CardSubtitle';
import CardTitle from '../typography/CardTitle';

interface ShortDomainCardProps {
    title: string
}

const ShortDomainCard: React.FunctionComponent<ShortDomainCardProps> = ({ title }) => {
    const domain = ApiEndpoints.affiliateBaseUrl + ApiEndpoints.domainBaseUrl + title;

    const handleClick = () => {
        GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Domain','Click', title)
    }

    return (
        <MotionRow lg="4" md="6" sm="6">
            <a href={domain} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                <Card>
                    <CardTitle>{title}.dk</CardTitle>
                    <CardSubtitle>Se domæne →</CardSubtitle>
                    <IconComponent 
                        src={DomainIcon} 
                        alt="Domæne" 
                        top={18}
                    />
                </Card>
            </a>
        </MotionRow>
    );
}

export default ShortDomainCard;