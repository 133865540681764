import React from "react";
import Headline from "../components/typography/Headline";
import Subtitle from "../components/typography/Subtitle";
import StandardText from "../components/typography/StandardText";
import WebsiteCard from "../components/cards/WebsiteCard";
import websiteOne from "../assets/unoeuro.png";
import websiteTwo from "../assets/wix.png";
import websiteThree from "../assets/weebly.png";
import { GoogleAnalyticsService } from "../services/GoogleAnalyticsService";
import Colors from "../constants/Colors";
import Dot from "../components/generalcomponents/Dot";
import SectionHeadline from "../components/typography/SectionHeadline";
import Card from "../components/cards/Card";
import ApiEndpoints from "../constants/ApiEndpoints";

class WebsitePage extends React.Component {
  componentDidMount() {
    GoogleAnalyticsService.initGoogleAnalytics();
    GoogleAnalyticsService.trackGoogleAnalyticsPageview();
  }

  render() {
    return (
      <div>
        <div style={headerBackground}>
          <div className="container text-center">
            <Headline>
              Hjemmeside
              <Dot />
            </Headline>
            <Subtitle text="Få en god hjemmeside" />
          </div>
        </div>
        <div style={contentBackground}>
          <div className="col-md-6 offset-md-3">
            <Card padding={30}>
              <StandardText>
                Der er mange steder på internettet, hvor du selv kan lave din
                egen hjemmeside. Det er derfor altid svært at sige, hvilken
                løsning der er bedst for dig. Gør dig en tjeneste og prøv at
                afdække funktioner på din hjemmesider før du går i gang. På
                denne måde er det lettere at finde det rigtige værktøj.
              </StandardText>
              <SectionHeadline showDot={false} marginTop={20}>
                Værktøjer på markedet
              </SectionHeadline>
              <StandardText>
                Det afhænger meget af formålet om du skal vælge at bygge din
                egen hjemmeside eller gå med en service. Langt de fleste
                hjemmesider kan dog ofte klares med et CMS (Content Management
                System). Det mest populære CMS er Wordpress, som i dag driver en
                stor del af hjemmesiderne på internettet. Det er mest kendt som
                en bloggingplatform, men kan i dag bruges til meget andet.
              </StandardText>
              <br></br>
              <StandardText>
                Der eksisterer i dag mange udvidelser til Wordpress og det er
                derfor typisk et godt bud til en førstegangshjemmeside. Unoeuro
                giver dig en let start, hvor du med meget hurtig opsætning kan
                drive en Wordpress side. Du kan klikke på linket herunder og
                læse mere. Udover Wordpress har vi nævnt nogle alternativer, som
                lader dig bygge din side med en editor. Disse er meget hurtige
                at sætte op, men har ikke samme fleksibilitet som en Wordpress
                side.
              </StandardText>
            </Card>
          </div>
          <div className="col-md-6 offset-md-3">
            <WebsiteCard
              title="Simply"
              tagline="Billige priser og hurtig opsætning"
              link={ApiEndpoints.adtractionSimplyAffiliateLink}
              imageSource={websiteOne}
            />
          </div>
          <div className="col-md-6 offset-md-3">
            <WebsiteCard
              title="Wix"
              tagline="Nem side med website builder"
              link="https://www.wix.com/"
              imageSource={websiteTwo}
            />
          </div>
          <div className="col-md-6 offset-md-3">
            <WebsiteCard
              title="Weebly"
              tagline="God website builder"
              link="https://www.weebly.com/"
              imageSource={websiteThree}
            />
          </div>
        </div>
      </div>
    );
  }
}

const headerBackground = {
  backgroundColor: Colors.themeColor,
  paddingTop: 50,
  paddingBottom: 50,
} as React.CSSProperties;

const contentBackground = {
  backgroundColor: Colors.lightThemeColor,
  paddingBottom: 30,
  paddingTop: 30,
} as React.CSSProperties;

export default WebsitePage;
