import React from "react";
import DomainLoop from "../components/frontpage/DomainLoop";
import Headline from "../components/typography/Headline";
import Subtitle from "../components/typography/Subtitle";
import { GoogleAnalyticsService } from "../services/GoogleAnalyticsService";
import Colors from "../constants/Colors";
import Dot from "../components/generalcomponents/Dot";
import CookieBanner from "../components/cookiebanner/CookieBanner";
import BannerIcon from "../assets/square-logo.png";
import ApiEndpoints from "../constants/ApiEndpoints";

interface ComponentProps {
  query: string;
}

class Homepage extends React.Component<ComponentProps> {
  componentDidMount() {
    GoogleAnalyticsService.initGoogleAnalytics();
    GoogleAnalyticsService.trackGoogleAnalyticsPageview();
  }

  render() {
    return (
      <div>
        <div style={headlineWrapper}>
          <div className="container">
            <div className="text-center">
              <Headline>
                Find et ledigt firmanavn
                <Dot />
              </Headline>
              <Subtitle text="Søg på et ord og få forslag til navne" />
            </div>
          </div>
        </div>
        <DomainLoop query={this.props.query} />
        <CookieBanner
          title="Rabat på hosting"
          subtitle="Klik her og få 3 måneders ekstra gratis hosting. Brug koden: 3GRATIS"
          imageSource={BannerIcon}
          link={ApiEndpoints.affiliateTrackingLink}
        />
      </div>
    );
  }
}

const headlineWrapper = {
  backgroundColor: Colors.headerBackground,
  paddingTop: 50,
  paddingBottom: 20,
} as React.CSSProperties;

export default Homepage;
