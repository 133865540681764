import React from 'react';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import Find from '../../assets/find.svg';
import Choose from '../../assets/choose.svg';
import Page from '../../assets/page.svg';

const Instructions: React.FunctionComponent = () => {
    return(
        <div style={instructionsContainer}>
            <div className="container">
                <div className="col-md-10 offset-md-1 text-center">
                    <div style={liftCards} className="row">
                        <div className="col-md-4">
                            <div className="instruction-card">
                                <h3 style={instructionsHeader}>Søg på et ord</h3>
                                <img style={image} src={Find} alt="Find domæne" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="instruction-card">
                                <h3 style={instructionsHeader}>Vælg dit navn</h3>
                                <img style={image} src={Choose} alt="Vælg domæne" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="instruction-card">
                                <h3 style={instructionsHeader}>Byg din hjemmeside</h3>
                                <img style={image} src={Page} alt="Byg hjemmeside" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const instructionsContainer = {
    backgroundColor: Colors.lightThemeColor,
    paddingTop: 15
} as React.CSSProperties;

const liftCards = {
    position: 'relative',
    bottom: 80
} as React.CSSProperties;

const instructionsHeader = {
    fontSize: 15,
    fontFamily: Variables.fontFamilyHeadline,
    fontWeight: Variables.fontWeightHeadline,
    color: Colors.themeColor,
    textTransform: 'uppercase',
    paddingBottom: 10
} as React.CSSProperties;

const image = {
    width: '100%',
    height: 100
} as React.CSSProperties;

export default Instructions;