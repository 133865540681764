import React from 'react';
import loader from '../../assets/loader.svg';
import Colors from '../../constants/Colors';
import Card from '../cards/Card';

interface ComponentProps {
    width?: number,
    height?: number
};

const NewLoadingIndicator: React.FunctionComponent<ComponentProps> = ({ width, height }) => {

    const loadingIndicator = {
        marginTop: 10,
        width: width,
        height: height,
        color: Colors.red
    };

    return(
        <div style={loadingWrapper}>
            <Card>
                <img src={loader} style={loadingIndicator} alt="Loader" />
            </Card>
        </div>
    );
}

const loadingWrapper = {
    marginTop: 0,
    marginBottom: 300
} as React.CSSProperties;

NewLoadingIndicator.defaultProps = {
    width: 100,
    height: 100
} as Partial<ComponentProps>;


export default NewLoadingIndicator;