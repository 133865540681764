import React from 'react';
import Dot from '../components/generalcomponents/Dot';
import Colors from '../constants/Colors';
import { GoogleAnalyticsService } from '../services/GoogleAnalyticsService';
import Headline from '../components/typography/Headline';
import Subtitle from '../components/typography/Subtitle';
import queryString, { ParsedQuery } from 'query-string'
import ContainerRow from '../components/generalcomponents/ContainerRow';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import MotionContainer from '../components/generalcomponents/MotionContainer';
import ShortDomainList from '../components/shortdomaincomponents/ShortDomainList';
import SearchBar from '../components/generalcomponents/SearchBar';
import SectionHeadline from '../components/typography/SectionHeadline';
import FilterLink from '../components/shortdomaincomponents/FilterLink';

interface ComponentProps extends RouteComponentProps<any> {
    query: string,
    history: any
};

type ComponentState = {
    searchQuery: string;
    analysisQuery: string;
    showClearTextIcon: boolean;
};

class ShortDomainsPage extends React.Component<ComponentProps, ComponentState> {

    state: ComponentState = {
        searchQuery: '',
        analysisQuery: '',
        showClearTextIcon: false
    };

    /**
     * Check URL parameter and search if
     * it is present.
     */
    componentDidMount = async (): Promise<void> => {
        const values: ParsedQuery<string> = queryString.parse(this.props.query);

        if (values.ord) {
            const word: string = values.ord.toString();

            this.setState({ 
                analysisQuery: word,
                searchQuery: word,
                showClearTextIcon: true
            });
        }

        GoogleAnalyticsService.initGoogleAnalytics();
        GoogleAnalyticsService.trackGoogleAnalyticsPageview();
    }

    /**
     * Called whenever search query is updated to get data
     * @param {Event} event 
     */
    handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        this.setState({
            searchQuery: event.target.value
        });

        if (event.target.value.length < 1) {
            this.clearText();
          } else {
            this.setState({
              showClearTextIcon: true,
            });
        }

        this.props.history.push("/korte-domaener?ord=" + event.target.value);
    }

    /**
     * Submit search bar.
     */
    handleSubmit = (event: React.ChangeEvent<HTMLInputElement>): void => {   
        event.preventDefault();
        this.setState({ analysisQuery: this.state.searchQuery });
        this.props.history.push("/korte-domaener?ord=" + this.state.searchQuery);
    };

    /**
     * Clear text in search bar.
     */
    clearText = (): void => {   
        this.setState({
            analysisQuery: '',
            searchQuery: '',
            showClearTextIcon: false
        });
        this.props.history.push("/korte-domaener");
    };

    /**
     * Handle a click on one of the filter
     * links in the sidebar.
     */
    filter = (text: string): void => {   

        if (text === '') {
            this.setState({
                analysisQuery: '',
                searchQuery: ''
            });
            this.props.history.push("/korte-domaener");
        } else {
            this.setState({
                analysisQuery: text,
                searchQuery: text
            });
            this.props.history.push("/korte-domaener?ord=" + text);   
        } 
    };

    render() {
        return(
            <div>
                <div style={headlineWrapper}>
                    <div className="container">
                        <div className="text-center" style={headlinePadding}>
                            <Headline>Korte domæner<Dot/></Headline>
                            <Subtitle text="Find domæner med få bogstaver" />
                        </div>
                    </div>
                    <ContainerRow centered={true}>
                        <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                            <SearchBar
                            value = {this.state.searchQuery}
                            placeholder="Søg på et ord.."
                            handleChange = {(event: any) => this.handleChange(event)}
                            clearText = {() => this.clearText()}
                            showClearTextIcon = {this.state.showClearTextIcon}
                            />
                        </div>
                    </ContainerRow>
                </div>

                <div style={containerMargin}>
                    <MotionContainer>
                        <ContainerRow>
                            <div className="col-md-3 col-lg-3">
                                <SectionHeadline fontSize={30} showDot={true}>
                                    Filter
                                </SectionHeadline>
                                <FilterLink text="Alle domæner" onClick={() => this.filter('')} />
                                <FilterLink text="Domæner med e" onClick={() => this.filter('e')} />
                                <FilterLink text="Domæner med i" onClick={() => this.filter('i')} />
                                <FilterLink text="Domæner med o" onClick={() => this.filter('o')} />
                            </div>
                            <div className="col-md-9 col-lg-9">
                                <ShortDomainList searchQuery={this.state.searchQuery} />
                            </div>
                        </ContainerRow>
                    </MotionContainer>
                </div>
            </div>
        );
    }
}

const headlineWrapper = {
    backgroundColor: Colors.headerBackground,
    paddingTop: 50,
    paddingBottom: 20
} as React.CSSProperties;

const headlinePadding = {
    paddingBottom: 20
} as React.CSSProperties;

const containerMargin = {
    marginTop: 20
} as React.CSSProperties;

export default withRouter(ShortDomainsPage);