import React from 'react';
import Variables from '../../constants/Variables';
import Colors from '../../constants/Colors';
import { IoMdAperture } from 'react-icons/io';

interface ComponentProps {
  text: string | string[],
  onPress: (event: React.MouseEvent<HTMLElement>) => void,
  paddingLeft?: number
  paddingRight?: number
}

const AnalysisButton: React.FunctionComponent<ComponentProps> = ({ text, onPress, paddingLeft, paddingRight }) => {
  
  const container = {
      backgroundColor: Colors.lightThemeColor,
      marginTop: 0,
      marginBottom: 0,
      borderRadius: 5,
      cursor: 'pointer'
  } as React.CSSProperties;

  const textStyle = {
      fontFamily: Variables.fontFamilyHeadline,
      color: Colors.almostBlack,
      fontSize: 12,
      paddingLeft: paddingLeft,
      paddingRight: paddingRight,
      paddingTop: 4,
      paddingBottom: 4,
      marginBottom: 0
} as React.CSSProperties;

  return(
    <div onClick={onPress} style={container}>
        <p style={textStyle}>
          {text}
          <IoMdAperture 
            style={icon}
            size={16}
          />
        </p>
    </div>
  );
}

AnalysisButton.defaultProps = {
  paddingLeft: 5,
  paddingRight: 5
} as Partial<ComponentProps>;

const icon = {
  position: 'relative',
  color: Colors.themeColor,
  right: 0,
  bottom: 1,
  marginLeft: 6
} as React.CSSProperties;

export default AnalysisButton;