import React from 'react'
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import { IoIosArrowDropright } from 'react-icons/io';

interface ComponentProps {
    text: string,
    link: string
};

const Button: React.FunctionComponent<ComponentProps> = ({ text, link }) => {
    return(
        <a style={defaultStyle} href={link}>
            {text}
            <IoIosArrowDropright 
            style={icon}
            size={20}
            />
        </a>
    );
}

const defaultStyle = {
    color: Colors.white,
    backgroundColor: Colors.themeColor,
    fontFamily: Variables.fontFamilyHeadline,
    fontSize: 14,
    paddingRight: 14,
    paddingLeft: 14,
    paddingTop: 14,
    paddingBottom: 14,
    borderRadius: 10,
    outline: 'none',
} as React.CSSProperties;

const icon = {
    color: Colors.white,
    marginLeft: 10,
} as React.CSSProperties;

export default Button;