import React from 'react';
import loader from '../../assets/loader.svg';
import Colors from '../../constants/Colors';

interface ComponentProps {
  paddingTop?: number,
  paddingBottom?: number
}

const LoadingIndicator: React.FunctionComponent<ComponentProps> = ({ paddingTop, paddingBottom }) => {

  const loadingContainer = {
    paddingTop: paddingTop,
    paddingBottom: paddingBottom,
    backgroundColor: Colors.lightThemeColor,
  } as React.CSSProperties;

  return(
    <div style={loadingContainer}>
      <div className="col-md-2 offset-md-5 text-center">
          <div className="loader-card push-up">
            <img src={loader} style={loadingIndicator} alt="Loader" />
          </div>
      </div>
    </div>
  );
}

LoadingIndicator.defaultProps = {
  paddingTop: 30,
  paddingBottom: 10
} as Partial<ComponentProps>;

const loadingIndicator = {
    marginTop: 10,
    width: 100,
    height: 100,
    color: Colors.red
} as React.CSSProperties;

export default LoadingIndicator;