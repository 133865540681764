import React from 'react';
import Colors from '../../constants/Colors';
import { FaSearch } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';

interface ComponentProps {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
};

const CustomButton: React.FunctionComponent<ComponentProps> = ({ onClick }) => {
    return (
        <button style={buttonStyle} onClick={(event) => onClick(event)} >
            <FaSearch
                style={searchIcon}
                size={25}
            />
        </button>
    );
}

const buttonStyle = {
    fontSize: 20,
    color: '#ffffff',
    textAlign: 'center',
    padding: 11,
    marginLeft: 10,
    backgroundColor: Colors.lightThemeColor,
    borderRadius: 5,
    width: (isMobile ? 70 : 100)
} as React.CSSProperties;

const searchIcon = {
    color: Colors.red
} as React.CSSProperties;

export default CustomButton;