import React from 'react';
import Headline from '../components/typography/Headline';
import Subtitle from '../components/typography/Subtitle';
import StandardText from '../components/typography/StandardText';
import { GoogleAnalyticsService } from '../services/GoogleAnalyticsService';
import Colors from '../constants/Colors';
import Dot from '../components/generalcomponents/Dot';
import Button from '../components/buttons/Button';
import SectionHeadline from '../components/typography/SectionHeadline';
import Card from '../components/cards/Card';

class AboutPage extends React.Component {

    componentDidMount() {
        GoogleAnalyticsService.initGoogleAnalytics();
        GoogleAnalyticsService.trackGoogleAnalyticsPageview();
    }
    
    render() {
        return(
            <div>
                <div style={headerBackground}>
                    <div className="text-center container">
                        <Headline>Om siden<Dot/></Headline>
                        <Subtitle text="Find et godt .dk domæne" />
                    </div>
                </div>
                <div style={contentBackground}>
                    <div className="col-md-6 offset-md-3">
                        <Card padding={30}>
                            <StandardText>
                                Der er efterhånden rigtig mange udbydere med domæner i Danmark.
                                Der er samtidig rigtig mange domæner, som allerede er optaget.
                                Det kan være rigtig svært at finde et domæne, som er unikt samtidig med, at det ligger tæt op ad din virksomheds formål.
                                Med denne side kan du derfor let få inspiration til et godt virksomhedsnavn.
                            </StandardText>
                            <SectionHeadline showDot={false} marginTop={20}>
                                Et godt domæne
                            </SectionHeadline>
                            <StandardText>
                                Det er tit en fordel at inkludere formålet i dit domæne, da du dermed lettere kan findes på Google og andre søgemaskiner.
                                Dette hjælper i høj grad med at vise søgemaskiner hvilket formål din side har.
                                Du kan blandt andet inkludere ord, som ligger tæt op af brugernes søgninger.
                                På den måde vil du automatisk blive associeret med dit ønskede formål.
                            </StandardText>
                            <SectionHeadline showDot={false} marginTop={20}>
                                Hvor henter vi information fra?
                            </SectionHeadline>
                            <StandardText>
                                Vi genererer alle vores domæner på baggrund af en lang liste af endelser, som vi har defineret. 
                                Dette skaber automatisk en masse gode forslag. Herefter kan du klikke ind og se om det pågældende domæne er ledigt.
                            </StandardText>
                            <SectionHeadline showDot={false} marginTop={20}>
                                Send os forslag og feedback
                            </SectionHeadline>
                            <StandardText>
                                Du er meget velkommen til at kontakte os og komme med forslag til forbedringer på siden.
                                Det kan også bare være, at du synes der mangler et væsentligt forslag eller lignende blandt alle ordene.
                                Skriv til os på:
                            </StandardText>
                            <br></br>
                            <div className="col-md-6 offset-md-3 text-center">
                                <Button
                                    text="Send en mail"
                                    link="mailto:kristofferaandreasen@gmail.com"
                                />
                                <br></br>
                                <br></br>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            );
        }
}

const headerBackground = {
    backgroundColor: Colors.themeColor,
    paddingTop: 50,
    paddingBottom: 50
} as React.CSSProperties;

const contentBackground = {
    backgroundColor: Colors.lightThemeColor,
    paddingBottom: 30,
    paddingTop: 30
} as React.CSSProperties;

export default AboutPage;