import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion"
import Colors from '../../constants/Colors';
import { ApiService } from '../../services/ApiService';
import CvrListCard from './CvrListCard';
import HouseIcon from '../../assets/home.png';
import Variables from '../../constants/Variables';

const CvrList = (props) => {
    const { text } = props;
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(null);

    /**
     * The effect hook fetching data
     * for the component.
     */
    useEffect(() => {

        /**
         * Fetch data from the Azure
         * function endpoint.
         */
        const fetchData = async () => {
          if (text !== null && text !== '') {
            setIsLoading(true);
            const companies = await ApiService.getCvrList(text);
            setCompanies(companies);
            setIsLoading(false);
          }
        }
        fetchData();
    }, [text])

    return (
        <div style={domainContainer}>
            <motion.div className="variantsContainer"
            variants={variantsContainer}
            initial="hidden"
            animate="visible">
                {!isLoading && companies && (
                    <div className="row">
                        {companies.slice(0, 8).map((item, key) =>
                            <CvrListCard
                              item={item}
                              key={item.id}
                            />
                        )}
                    </div>                        
                )}
                {!companies && (
                    <div style={card} className="card elevation text-center">
                      <div className="header">
                          <img style={icon} src={HouseIcon} alt="virksomhed" />
                          <h3 style={cardTitle}>Ingen resultater fra CVR registret</h3>
                          <p style={cardSubtitle}>Prøv at søg efter andre ord</p>
                      </div>
                    </div>
                )}
            </motion.div>
        </div>
      );
}
  
CvrList.propTypes = {
  text: PropTypes.string.isRequired
};

const domainContainer = {
  backgroundColor: Colors.lightThemeColor
};

const variantsContainer = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.1,
      when: "beforeChildren",
      staggerChildren: 0.03
    }
  },
};

const card = {
  backgroundColor: Colors.white,
  paddingLeft: 30,
  paddingTop: 50,
  paddingBottom: 50,
  paddingRight: 30,
  marginBottom: 20,
  borderRadius: 15,
  border: "1px solid #E8EAED",
};

const cardTitle = {
  color: Colors.almostBlack,
  fontWeight: Variables.fontWeightHeadline,
  fontSize: 25,
  lineHeight: 1,
};

const cardSubtitle = {
  color: Colors.grey,
  fontWeight: Variables.fontSizeSubtitle,
  fontSize: 20,
  lineHeight: 1,
  marginBottom: 0,
};

const icon = {
  paddingBottom: 10
};

export default CvrList;