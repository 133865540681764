import React from 'react';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';

interface StandardTextProps {
  children: string | string[],
  fontSize?: number
}

const StandardText: React.FunctionComponent<StandardTextProps> = ({ children, fontSize }) => {
  
  const defaultStyling = {
    color: Colors.almostBlack,
    fontFamily: Variables.fontFamilyText,
    fontSize: fontSize,
    marginBottom: 0
  };

  return(
      <p style={defaultStyling}>
          {children}
      </p>
  );
}

StandardText.defaultProps = {
  fontSize: Variables.fontSizeSubtitle
} as Partial<StandardTextProps>;

export default StandardText;