import React from 'react';
import { DomainService } from '../../services/DomainService';
import HoverCard from '../cards/HoverCard';
import SearchBar from '../generalcomponents/SearchBar';
import LoadingIndicator from '../generalcomponents/LoadingIndicator';
import Colors from '../../constants/Colors';
import _ from 'lodash';
import Tag from '../generalcomponents/Tag';
import Variables from '../../constants/Variables';
import DomainIcon from '../../assets/internet.png';
import LoadMoreButton from '../generalcomponents/LoadMoreButton';
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';
import queryString, { ParsedQuery } from 'query-string'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Instructions from './Instructions';
import { isMobile } from "react-device-detect";
import SimpleDanishDomainCard from '../cards/SimpleDanishDomainCard';
import MotionContainer from '../generalcomponents/MotionContainer';
import ContainerRow from '../generalcomponents/ContainerRow';

interface ComponentProps extends RouteComponentProps<any> {
    query: string,
    history: any
};

type ComponentState = {
    searchQuery: string;
    maxNumberOfRecords: number;
    loading: boolean;
    showLoadMoreButton: boolean;
    domainList: Array<Domain>,
    showClearTextIcon: boolean
};

interface Domain {
    id: number,
    word: string
}

class DomainLoop extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        // This adds a debounce everytime method is called
        this.fetchDomainData = isMobile? _.debounce(this.fetchDomainData, 1000) : _.debounce(this.fetchDomainData, 300)
    }

    state: ComponentState = {
        searchQuery: '',
        maxNumberOfRecords: 100,
        loading: false,
        showLoadMoreButton: true,
        domainList: [],
        showClearTextIcon: false
    };

    /**
     * Get query string and
     * fetch relevant items.
     */
    componentDidMount = async (): Promise<void> => {
        const values: ParsedQuery<string> = queryString.parse(this.props.query);

        if (values.search) {
            const word: string = values.search.toString();

            this.setState({ 
                searchQuery: word,
                showClearTextIcon: true
            });

            this.fetchDomainData(word);
        }
    }

    /**
     * Fetch domain data by generating lists
     * from the data.
     * 
     * @param {String} text 
     */
    fetchDomainData = async (text: string): Promise<void> => {
        if (text) {
            this.setState({ 
                loading: true,
                domainList: []
            });

            let domainArray: Domain[] = await DomainService.createDomainList(text);
            let domains: Domain[] = await DomainService.randomizeArray(domainArray);

            this.setState({
                domainList: domains,
                loading: false
            });
            
            GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Search','Typing', text.toLowerCase());
        }
    }

    /**
     * Handle click on a tag and search
     * @param {String} text 
     */
    tagClick = async (text: string): Promise<void> => {
        this.setState({
            searchQuery: text,
            showClearTextIcon: true,
        })
        
        this.fetchDomainData(text);
        this.props.history.push("/?search=" + text);

        GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Tag','Click',  text)
     }

     /**
     * Clear text in search bar.
     */
    clearText = (): void => {   
        this.setState({
            searchQuery: '',
            domainList: [],
            showClearTextIcon: false,
            loading: false
        });
        this.props.history.push("/");
    };

    /**
     * Called whenever search query is updated to get data
     * @param {Event} event 
     */
    handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const searchTerm: string = event.target.value
            .toLowerCase()
            .trim()
            .replace(/[^a-zA-Z0-9-æøåÆØÅ]/g, '');

        this.setState({
            searchQuery: searchTerm
        });

        if (searchTerm.length < 1) {
            this.clearText();
          } else {
            this.setState({
              showClearTextIcon: true,
            });
        }

        this.fetchDomainData(searchTerm);
        this.props.history.push("/?search=" + searchTerm);
    }

    /**
     * Loads more domains when button is clicked.
     */
    handleLoadMore = async (): Promise<void> => {
        if (this.state.searchQuery !== '') {
            this.setState({ 
                maxNumberOfRecords: this.state.maxNumberOfRecords + 100 
            }, async () => {
                let numberOfDomains: number = await DomainService.getNumberOfDomains();

                if (numberOfDomains < this.state.maxNumberOfRecords) {
                    this.setState({ showLoadMoreButton: false })
                }
            });

            GoogleAnalyticsService.triggerGoogleAnalyticsEvent('Load More Button','Click')
        }
    }

    render() {
        return(
            <div>
                <div style={backgroundThemeColor}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <SearchBar
                                value = {this.state.searchQuery}
                                placeholder="Søg på et ord.."
                                handleChange = {(event: any) => this.handleChange(event)}
                                clearText = {() => this.clearText()}
                                showClearTextIcon = {this.state.showClearTextIcon}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={tagContainer} className="container-fluid">
                    <div className="col-md-6 offset-md-3 text-center">
                        <ul className="list-inline">
                            <li className="list-inline-item"><p style={textStyle}>Forslag:</p></li>
                            <li className="list-inline-item"><Tag text="Bil" onPress={() => this.tagClick("bil")} /></li>
                            <li className="list-inline-item"><Tag text="Vin" onPress={() => this.tagClick("vin")} /></li>
                            <li className="list-inline-item"><Tag text="Bord" onPress={() => this.tagClick("bord")} /></li>
                            <li className="list-inline-item"><Tag text="Fodbold" onPress={() => this.tagClick("fodbold")} /></li>
                        </ul>
                    </div>
                </div>

                {!!this.state.loading && (
                    <LoadingIndicator/>
                )}

                {!this.state.loading && this.state.domainList.length < 1 && (  
                    <Instructions/>
                )}
                
                {!this.state.loading && this.state.domainList.length > 0 && (  
                    <div style={domainContainer} className="container-fluid">
                        <MotionContainer>
                            <div style={wrapper}>
                                <ContainerRow>
                                    <SimpleDanishDomainCard text={this.state.searchQuery} />
                                    {this.state.domainList.slice(0, this.state.maxNumberOfRecords).map((item, key) =>
                                        <HoverCard
                                        item={item}
                                        key={item.id}
                                        imageSource={DomainIcon}
                                        />
                                    )}
                                </ContainerRow>
                            </div>
                        </MotionContainer>
                    </div>
                )}
                {!this.state.loading && this.state.domainList.length > 0 && this.state.showLoadMoreButton &&( 
                    <div style={wrapper}>
                        <LoadMoreButton 
                            onClick={this.handleLoadMore}
                        />
                    </div>
                )}                
            </div>
        );
    }
}

const backgroundThemeColor = {
    backgroundColor: Colors.themeColor
} as React.CSSProperties;

const tagContainer = {
    backgroundColor: Colors.themeColor,
    paddingBottom: 70,
} as React.CSSProperties;

const domainContainer = {
    backgroundColor: Colors.lightThemeColor,
    paddingTop: 35
} as React.CSSProperties;

const wrapper = {
    position: 'relative',
    bottom: 80,
    width: '100%',
    padding: 0,
    margin: 0,
} as React.CSSProperties;

const textStyle = {
    color: Colors.white,
    opacity: 0.8,
    fontFamily: Variables.fontFamilyText,
    fontSize: 14,
    lineHeight: 1,
    marginBottom: 10,
} as React.CSSProperties;

export default withRouter(DomainLoop);
