import React, { useState, useEffect } from 'react';
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';
import ApiEndpoints from '../../constants/ApiEndpoints';
import CardTitle from '../typography/CardTitle';
import DomainIcon from '../../assets/internet.png';
import Colors from '../../constants/Colors';
import { isMobile } from 'react-device-detect';

interface Domain {
  word: string
};

interface ComponentProps {
  item: Domain
};

const TableItem: React.FunctionComponent<ComponentProps> = ({ item }) => {
  const [showItems, setShowItems] = useState<boolean>(true);
  const domain: string = ApiEndpoints.affiliateBaseUrl + ApiEndpoints.domainBaseUrl + item.word;

  const handleClick = () => {
    GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Domain','Click', item.word)
  };

  useEffect(() => {
    if (isMobile) {
      setShowItems(false);
    }
  }, [])

  return(
        <tr>
            <td>
              <a href={domain} style={linkStyles} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                <CardTitle 
                    fontSize={17} 
                    marginBottom={0}
                    lineHeight={1.8}
                >
                    {item.word}
                </CardTitle>
              </a>
            </td>
            {showItems && ( 
              <td><a href={domain} style={linkStyles} target="_blank" rel="noopener noreferrer" onClick={handleClick}>Se domæne →</a></td>
            )}
            {showItems && ( 
              <td><img style={iconStyles} src={DomainIcon} alt="Domæne" /></td>
            )}
        </tr>
  );
}

const linkStyles = {
  color: Colors.almostBlack,
  fontSize: 18,
  lineHeight: 1.8,
  verticalAlign: 'middle'
} as React.CSSProperties;

const iconStyles = {
    height: 28,
    paddingTop: 2
} as React.CSSProperties;

export default TableItem;