import React from 'react';
import { BrowserRouter as Router, Route, RouteComponentProps } from "react-router-dom";
import Homepage from './pages/Homepage';
import WebsitePage from './pages/WebsitePage';
import AboutPage from './pages/AboutPage';
import Footer from './components/footer/Footer';
import Colors from './constants/Colors';
import AnalysisPage from './pages/AnalysisPage';
import ShortDomainsPage from './pages/ShortDomainsPage';
import ConsultantPage from './pages/ConsultantPage';
import ReactstrapNavbar from './components/navbar/ReactstrapNavbar';

interface ComponentProps extends RouteComponentProps<any> {
  location: any
};

const Index: React.FunctionComponent<ComponentProps> = ({ location }) => {
  return (
    <Homepage 
      query={location.search}
    />
  );
}

const About: React.FunctionComponent = () => {
  return <AboutPage/>;
}

const Website: React.FunctionComponent = () => {
  return <WebsitePage/>;
}

const Analysis: React.FunctionComponent<ComponentProps> = ({ location }) => {
  return (
    <AnalysisPage 
      query={location.search}
    />
  );
}

const ShortDomains: React.FunctionComponent<ComponentProps> = ({ location }) => {
  return (
    <ShortDomainsPage 
      query={location.search}
    />
  );
}

const Consultant: React.FunctionComponent<ComponentProps> = ({ location }) => {
  return (
    <ConsultantPage 
      query={location.search}
    />
  );
}

const App: React.FunctionComponent = () => {
  return (
    <Router>
        <div style={pageWrapper} className="App">
            <ReactstrapNavbar/>
            <Route exact path="/" component={Index} />
            <Route path="/om-siden/" component={About} />
            <Route path="/hjemmeside/" component={Website} />
            <Route path="/analyse" component={Analysis} />
            <Route path="/korte-domaener" component={ShortDomains} />
            <Route path="/konsulent" component={Consultant} />
            <Footer/>
        </div>
    </Router>
  );
}

const pageWrapper = {
  position: 'absolute',
  left: 0,
  width: '100%',
  minHeight: '100%',
  backgroundColor: Colors.lightThemeColor
} as React.CSSProperties;

export default App;