import React from 'react';

interface ComponentProps {
  children: any,
  centered?: boolean
};

const ContainerRow: React.FunctionComponent<ComponentProps> = ({ children, centered }) => {
  const isCentered = (centered === true ? "row text-center" : "row")
  return(
    <div className="container">
        <div className={isCentered}>
            {children}
        </div>                        
    </div>
  );
}

ContainerRow.defaultProps = {
  centered: false
} as Partial<ComponentProps>;

export default ContainerRow;