const tintColor = '#00AF87';
const themeColor = '#19367C';
const lightThemeColor = '#EAEEF7';
const darkThemeColor = '#132B63';
const headlineColor = '#FFF';
const subtitleColor = '#FFF';
const navigationLinks = '#FFF';

export default {
  tintColor,
  themeColor,
  lightThemeColor,
  darkThemeColor,
  headlineColor,
  subtitleColor,
  navigationLinks,

  // Header
  headerBackground: themeColor,
  navbarColor: themeColor,
  
  // Pure colors
  white: '#fff',
  black: '#000',
  green: '#46A597',
  almostBlack: '#292929',
  grey: '#868c91',
  lightGrey: '#DBDBD9',
  red: '#AD0841',

  // Various elements
  borderColor: '#6B57FF',
};