export default {
  // DK Hostmaster
  hostmasterBase: "https://whois-api.dk-hostmaster.dk/domain/",
  countryCodeDenmark: ".dk",

  // Unoeuro Azure Function Endpoint
  unoeuroEnpoint:
    "https://firma-navn-functions.azurewebsites.net/api/CheckDomains?name=",
  unoeuroFunctionCode:
    "&code=5M9cifTZKmKVFUMmYDCaaBElK8sLLF5IwY6a84ivazN/kjURbtFmFA==",
  // Norway domains
  unoeuroEndpointNO:
    "https://firma-navn-functions.azurewebsites.net/api/CheckDomainNorway?name=",
  unoeuroFunctionCodeNO:
    "&code=5M9cifTZKmKVFUMmYDCaaBElK8sLLF5IwY6a84ivazN/kjURbtFmFA==",

  // Instagram
  instagramEndpoint:
    "https://firma-navn-functions.azurewebsites.net/api/CheckInstagramUsername?name=",
  instagramFunctionCode:
    "&code=eCzkflKGcCmtXRUU2LkwF3903pNLqaWbxl99DnCMYdocjdDUmlk/7Q==",

  // Facebook
  facebookEndpoint:
    "https://firma-navn-functions.azurewebsites.net/api/CheckFacebookUsername?name=",
  facebookFunctionCode:
    "&code=CkskU30EUHE37HXUaQkHnzqdY3zajfjY7ClDg5hseBaj3LfDrVWnoA==",

  // Twitter
  twitterEndpoint:
    "https://firma-navn-functions.azurewebsites.net/api/CheckTwitterUsername?name=",
  twitterFunctionCode:
    "&code=705ZN8S8ldAAFeeBPzSd0255fJxz5Xt4s8KaqI/k3pRTT3vbL8aeJA==",

  // Youtube
  youtubeEndpoint:
    "https://firma-navn-functions.azurewebsites.net/api/CheckYoutubeUsername?name=",
  youtubeFunctionCode:
    "&code=8rmaIafHCmU45kKnerCEHBwwMJBomGT9iQuBDdpsjE6kKnfGgkzHBw==",

  // Fetch CVR data from function
  cvrDataEndpoint:
    "https://firma-navn-functions.azurewebsites.net/api/FetchCvrData?text=",
  cvrFunctionCode:
    "&code=3FR3aCS2qsUItLdvE8LsrFdlj2qgJNCnJXk70oGK7trj9pa3BxY8zA==",

  // Affiliate
  affiliateBaseUrl:
    "https://track.adtraction.com/t/t?a=1676137662&as=1696610579&t=2&tk=1&url=",
  affiliateTrackingLink:
    "https://track.adtraction.com/t/t?a=1676137662&as=1696610579&t=2&tk=1",
  adtractionSimplyAffiliateLink:
    "https://track.adtraction.com/t/t?a=1676137662&as=1696610579&t=2&tk=1",

  // Domain function endpoint
  domainFunctionEndpoint:
    "https://firma-navn-functions.azurewebsites.net/api/GetAllDomains?code=EIuIByLZpVfbCjNMOVuC4Gdmu06z8hs8N4eHUnGk1zV9KZ8spQFnvQ==",

  // Analysis Page
  cvrUrl: "https://cvrapi.dk/api?search=",
  cvrCountry: "&country=dk",
  domainBaseUrl: "https://www.simply.com/dk/domains/?q=",
  twitterBase: "https://twitter.com/",
};
