import React from 'react';
import PropTypes from 'prop-types';
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';
import ApiEndpoints from '../../constants/ApiEndpoints';
import DomainIcon from '../../assets/internet.png';
import Card from '../cards/Card';
import MotionRow from '../generalcomponents/MotionRow';
import IconComponent from '../icons/IconComponent';
import CardSubtitle from '../typography/CardSubtitle';
import CardTitle from '../typography/CardTitle';

const AnalysisDomainCard = ({ item }) => {
    const domain = ApiEndpoints.affiliateBaseUrl + ApiEndpoints.domainBaseUrl + item.word;

    const handleClick = () => {
        GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Domain','Click', item.word)
    }

    return (
        <MotionRow>
            <a href={domain} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                <Card>
                    <CardTitle>{item.word}.dk</CardTitle>
                    <CardSubtitle>Se domæne →</CardSubtitle>
                    <IconComponent src={DomainIcon} alt="Domæne" />
                </Card>
            </a>
        </MotionRow>
    );
}

AnalysisDomainCard.propTypes = {
    item: PropTypes.object.isRequired
};

export default AnalysisDomainCard;