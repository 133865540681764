import React from 'react';

interface ComponentProps {
    children: any,
    moveupPadding?: number
};

const MoveupContainer: React.FunctionComponent<ComponentProps> = ({ children, moveupPadding }) => {

    const moveupStyles = {
        position: 'relative',
        bottom: moveupPadding,
        paddingBottom: '$-{moveupPadding}'
    } as React.CSSProperties;

    return(
        <div style={moveupStyles}>
            {children}
        </div>
    );
}

MoveupContainer.defaultProps = {
    moveupPadding: 40
} as Partial<ComponentProps>;

export default MoveupContainer;