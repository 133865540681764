import React from 'react';
import Colors from '../../constants/Colors';
import { ApiService } from '../../services/ApiService';
import ShortDomainCard from './ShortDomainCard';
import MotionContainer from '../generalcomponents/MotionContainer';
import ContainerRow from '../generalcomponents/ContainerRow';
import _ from 'lodash';
import { isMobile } from "react-device-detect";
import LoadMoreButton from '../generalcomponents/LoadMoreButton';
import DomainLoadingIndicator from './DomainLoadingIndicator';

interface ComponentProps {
    searchQuery: string
};

type ComponentState = {
    loading: boolean;
    domainList: Array<Domain>;
    filteredList: Array<Domain>;
    maxNumberOfRecords: number;
    domainCount: number;
    showLoadMoreButton: boolean;
};

interface Domain {
    DomainName: string,
    RowKey: string
};

class ShortDomainList extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        // This adds a debounce everytime method is called
        this.filterDomains = isMobile? _.debounce(this.filterDomains, 1000) : _.debounce(this.filterDomains, 300)
    }

    state: ComponentState = {
        loading: true,
        domainList: [],
        filteredList: [],
        maxNumberOfRecords: 50,
        domainCount: 0,
        showLoadMoreButton: true
    };

    /**
     * Get all domains from Table Storage
     */
    componentDidMount = async (): Promise<void> => {
        this.setState({ loading: true });
        const domains = await ApiService.getAllDomains();
        this.setState({ 
            domainList: domains,
            filteredList: domains,
            domainCount: domains.length,
            loading: false        
        });
    }

    /**
     * Update component when search query changes
     */
    componentDidUpdate = async (prevProps: ComponentProps) => {
        if (prevProps.searchQuery !== this.props.searchQuery) {
            await this.resetData();
            this.filterDomains(this.props.searchQuery);
        }
    }

    /**
     * Reset data to original list
     */
    resetData = async (): Promise<void> => {
        this.setState({ filteredList: this.state.domainList });
    }

    /**
     * Filter domains
     */
    filterDomains = (searchQuery: string) => {
        let newDomains = this.state.domainList.filter(function (domain) {
            return domain.DomainName.includes(searchQuery)
        });

        
        let loadmore = true;
        if (newDomains.length < this.state.maxNumberOfRecords) {
            loadmore = false;
        }

        this.setState({ 
            filteredList: newDomains,
            domainCount: newDomains.length,
            showLoadMoreButton: loadmore
        });
    }

    /**
     * Loads more domains when button is clicked.
     */
    handleLoadMore = async (): Promise<void> => {
        this.setState({ 
            maxNumberOfRecords: this.state.maxNumberOfRecords + 50
        }, async () => {
            if (this.state.domainCount < this.state.maxNumberOfRecords) {
                this.setState({ showLoadMoreButton: false })
            }
        });
    }

    render() {
        return(
            <div style={domainContainer}>
                {this.state.loading && (
                    <ContainerRow centered={true}>
                        <div className="col-md-4 offset-md-4">
                            <DomainLoadingIndicator/>
                        </div>
                    </ContainerRow>
                )}
                {this.state.filteredList && ( 
                    <MotionContainer>
                        <ContainerRow>
                            {this.state.filteredList.slice(0, this.state.maxNumberOfRecords).map((item: Domain, key) =>
                                <ShortDomainCard
                                title={item.DomainName}
                                key={item.RowKey}
                                />
                            )}
                        </ContainerRow>
                        <ContainerRow>
                            {this.state.filteredList.length > 0 && this.state.showLoadMoreButton &&( 
                                <LoadMoreButton 
                                    onClick={this.handleLoadMore}
                                />
                            )} 
                        </ContainerRow>
                    </MotionContainer>
                )}
            </div>
        );
    }
}

const domainContainer = {
    backgroundColor: Colors.lightThemeColor,
    marginBottom: 75
} as React.CSSProperties;

export default ShortDomainList;