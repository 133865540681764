import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion"
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import Checkmark from '../../assets/checked.png';
import NotAvailable from '../../assets/not-available.png';
import Loader from '../../assets/rings-spinner.svg';
import Youtube from '../../assets/youtube.png';
import { ApiService } from '../../services/ApiService';
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';

const YoutubeCard = (props) => {
    const { text } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState(null);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
    const domainLink = 'https://youtube.com/account';

    /**
     * The effect hook fetching data
     * for the component.
     */
    useEffect(() => {
        const fetchData = async () => {
          if (text !== null && text !== '') {
            const usernameAvailability = await ApiService.isYoutubeHandleAvailable(text);
            setIsUsernameAvailable(usernameAvailability);
            setTitle(text);
            setIsLoading(false);
          }
        }
        fetchData();
    }, [text])

    /**
     * Triggers an event sent to Google Analytics with
     * the domain click.
     */
    const handleClick = () => {
        GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Domain','Click', props.text)
    }

    return (
        <motion.div className="col-lg-6 col-md-6 col-sm-6 col-12" variants={itemStyles}>
            <a href={domainLink} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                <div style={card} className="card elevation">
                    {isLoading && (
                        <div>
                            <img style={mediaIcon} src={Youtube} alt="Youtube-ikon" />
                            <h3 style={cardTitle}>@{props.text}</h3>
                            <p style={cardSubtitle}>Checker youtube...</p>
                            <img style={loadingIndicator} src={Loader} alt="loading-ikon" />
                        </div>
                    )}
                    {!isLoading && isUsernameAvailable && isUsernameAvailable !== null && (
                        <div>
                            <img style={mediaIcon} src={Youtube} alt="Youtube-ikon" />
                            <h3 style={cardTitle}>@{title}</h3>
                            <p style={cardSubtitle}>Ledigt brugernavn</p>
                            <img style={icon} src={Checkmark} alt="analyse-ikon" />
                        </div>
                    )}
                    {!isLoading && !isUsernameAvailable && !isUsernameAvailable !== null && (
                        <div>
                            <img style={mediaIcon} src={Youtube} alt="Youtube-ikon" />
                            <h3 style={cardTitle}>@{title}</h3>
                            <p style={cardSubtitle}>Brugernavnet er ikke ledigt</p>
                            <img style={icon} src={NotAvailable} alt="analyse-ikon" />
                        </div>
                    )}
                </div>
            </a>
        </motion.div>
        );
    }
  
    YoutubeCard.propTypes = {
      text: PropTypes.string.isRequired
    };
  
    const itemStyles = {
      hidden: { y: 20, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1
      }
    };
    
    const card = {
      backgroundColor: Colors.white,
      paddingLeft: 100,
      paddingTop: 30,
      paddingBottom: 30,
      paddingRight: 80,
      marginBottom: 20,
      borderRadius: 15,
      border: "1px solid #E8EAED",
    };
    
    const cardTitle = {
      color: Colors.almostBlack,
      fontWeight: Variables.fontWeightHeadline,
      fontSize: 20,
      lineHeight: 1,
    };
    
    const cardSubtitle = {
      color: Colors.grey,
      fontWeight: Variables.fontSizeSubtitle,
      fontSize: 15,
      lineHeight: 1,
      marginBottom: 0,
    };

    const icon = {
      position: 'absolute',
      right: 25,
      top: 27,
    };

    const loadingIndicator = {
      width: 50,
      height: 50,
      color: Colors.red,
      position: 'absolute',
      right: 25,
      top: 27,
    };

    const mediaIcon = {
      position: 'absolute',
      left: 25,
      top: 27,
    };
  
  export default YoutubeCard;