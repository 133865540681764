import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion"
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import Checkmark from '../../assets/checked.png';
import NotCheckmark from '../../assets/not-available.png';

const NumbersDomainCard = (props) => {
    const { text } = props;
    const [title, setTitle] = useState('Ingen tal');
    const [subtitle, setSubtitle] = useState('Navnet indeholder ikke tal');
    const [iconSource, setIconSource] = useState(Checkmark);
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    useEffect(() => {
        if (text !== '') {
            const numbersFound = isArrayElementInString(text, numbers);

            if (numbersFound > 0) {
                setTitle('Undgå tal');
                setSubtitle('Kan ikke anbefales i domæner');
                setIconSource(NotCheckmark);
            }
        }
    }, [text, numbers])

    /**
     * Returns the number of elements from
     * the array found in a string.
     * 
     * @param {String} string 
     * @param {Array} array 
     */
    const isArrayElementInString = (string, array) => {
        let elementsFound = 0;
        for (let index = 0; index < array.length; index++) {
            if (string.indexOf(array[index]) > -1)
            {
                elementsFound++;
            }
        }

        return elementsFound;
    }

    return (
        <motion.div className="col-lg-4 col-md-6 col-sm-6 col-12" variants={itemStyles}>
            <div style={card} className="card elevation">
                <div className="header">
                    <h3 style={cardTitle}>{title}</h3>
                    <p style={cardSubtitle}>{subtitle}</p>
                    <img style={icon} src={iconSource} alt="analyse-ikon" />
                </div>
            </div>
        </motion.div>
        );
    }
  
  NumbersDomainCard.propTypes = {
    text: PropTypes.string.isRequired
  };
  
  const itemStyles = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  
  const card = {
    backgroundColor: Colors.white,
    padding: 30,
    marginBottom: 20,
    borderRadius: 15,
    border: "1px solid #E8EAED",
  };
  
  const cardTitle = {
    color: Colors.almostBlack,
    fontWeight: Variables.fontWeightHeadline,
    fontSize: 20,
    lineHeight: 1,
  };
  
  const cardSubtitle = {
    color: Colors.grey,
    fontWeight: Variables.fontSizeSubtitle,
    fontSize: 15,
    lineHeight: 1,
    marginBottom: 0,
  };

  const icon = {
    position: 'absolute',
    right: 25,
    top: 27,
  };
  
  export default NumbersDomainCard;