import ApiEndpoints from '../constants/ApiEndpoints';
import jsonData from '../data/prefix.json';
import postJsonData from '../data/suffix.json';
import analysisprefix from '../data/analysisprefix.json';
import analysissuffix from '../data/analysissuffix.json';
import consultantprefix from '../data/consultantprefix.json';
import consultantsuffix from '../data/consultantsuffix.json';

/**
 * Check if a domain is available
 * from DK Hostmaster using their
 * free API.
 * 
 * @param {String} domain 
 */
const isDomainAvailable = async (domain: string): Promise<boolean | undefined> => {
  try {
    const response: Response = await fetch(ApiEndpoints.hostmasterBase + domain + ApiEndpoints.countryCodeDenmark, {
      headers: { 'Accept': 'application/json' }
    })

    if (response.status === 404) {
      return true;
    }

    if (response.status === 200) {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Check if a domain is available
 * from Unoeuro using their
 * free API.
 * 
 * @param {String} domain 
 */
const isDotComDomainAvailable = async (domain: string): Promise<boolean | undefined> => {
  try {
    const response: Response = await fetch(ApiEndpoints.unoeuroEnpoint + domain + ApiEndpoints.unoeuroFunctionCode, {
      headers: { 'Accept': 'application/json' }
    })

    if (response.status === 200) {
      const responseJson = await response.json();
      const responseObject = JSON.parse(responseJson);

      if (responseObject.domain.status === 'available') {
        return true;
      } else {
        return false;
      }
    }

    if (response.status === 404) {
      return false;
    }

  } catch (error) {
    console.log(error);
  }
}

/**
 * Check the Danish CVR Api to see
 * if there is a company with a similar name.
 * 
 * @param {String} text 
 */
const getCvrCompanies = async (text: string): Promise<any | undefined> => {
  try {
    const response: Response = await fetch(ApiEndpoints.cvrUrl + text + ApiEndpoints.cvrCountry, {
      headers: {
        'Accept': 'application/json',
        'User-Agent': 'www.firma-navn.dk - Kristoffer Andreasen - kristofferaandreasen@gmail.com'
      }
    })

    if (response.status === 404) {
      return '';
    }

    if (response.status === 200) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Check the Danish CVR website and
 * return data for the search word.
 * 
 * @param {String} text 
 */
const getCvrList = async (text: string): Promise<Object | undefined> => {
  try {
    const response: Response = await fetch(ApiEndpoints.cvrDataEndpoint + text + ApiEndpoints.cvrFunctionCode, {
      headers: { 'Accept': 'application/json' }
    })

    if (response.status === 404) {
      return '';
    }

    if (response.status === 200) {
      const responseData = await response.json();
      const responseObject = JSON.parse(responseData);

      return responseObject;
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Check the custom Azure Function
 * to see if the Instagram handle
 * is available.
 * 
 * @param {String} text 
 */
const isInstagramHandleAvailable = async (text: string): Promise<Object | undefined> => {
  try {
    const response: Response = await fetch(ApiEndpoints.instagramEndpoint + text + ApiEndpoints.instagramFunctionCode, {
      headers: { 'Accept': 'application/json' }
    })

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Check the custom Azure Function
 * to see if the Twitter handle
 * is available.
 * 
 * @param {String} text 
 */
const isTwitterHandleAvailable = async (text: string): Promise<Object | undefined> => {
  try {
    const response: Response = await fetch(ApiEndpoints.twitterEndpoint + text + ApiEndpoints.twitterFunctionCode, {
      headers: { 'Accept': 'application/json' }
    })

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Check the custom Azure Function
 * to see if the Youtube handle
 * is available.
 * 
 * @param {String} text 
 */
const isYoutubeHandleAvailable = async (text: string): Promise<Object | undefined> => {
  try {
    const response: Response = await fetch(ApiEndpoints.youtubeEndpoint + text + ApiEndpoints.youtubeFunctionCode, {
      headers: { 'Accept': 'application/json' }
    })

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Check the custom Azure Function
 * to see if the Facebook handle
 * is available.
 * 
 * @param {String} text 
 */
const isFacebookHandleAvailable = async (text: string): Promise<Object | undefined> => {
  try {
    const response: Response = await fetch(ApiEndpoints.facebookEndpoint + text + ApiEndpoints.facebookFunctionCode, {
      headers: { 'Accept': 'application/json' }
    })

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Fetch twitter to see if a user exists.
 * It's not done with any API. It's simply
 * checking the Twitter site.
 * 
 * @param {String} text 
 */
const checkTwitterHandle = async (text: string): Promise<boolean | undefined> => {
  try {
    const response: Response = await fetch(ApiEndpoints.twitterBase + text, {
      headers: { 'Accept': 'application/json' }
    })

    if (response.status === 404) {
      return true;
    }

    if (response.status === 200) {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get all domains from Table Storage
 * in Azure
 */
const getAllDomains = async (): Promise<any | undefined> => {
  try {
    const response: Response = await fetch(ApiEndpoints.domainFunctionEndpoint, {
      headers: { 'Accept': 'application/json' }
    })

    if (response.status === 404) {
      return '';
    }

    if (response.status === 200) {
      const responseData = await response.json();
      const responseObject = JSON.parse(responseData);

      return responseObject;
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Domain types in JSON data.
 */
interface Domain {
  id: number,
  word: string
}

/**
 * Get all pre words to pass to the API
 */
const getPrefixWords = async (): Promise<Array<Domain>> => {
  return jsonData;
}

/**
 * Get all post words to pass to the API
 */
const getSuffixWords = async (): Promise<Array<Domain>> => {
  return postJsonData;
}

/**
 * Get all analysis data
 */
const getAnalysisPrefix = async (): Promise<Array<Domain>> => {
  return analysisprefix;
}

/**
 * Get all analysis data
 */
const getAnalysisSuffix = async (): Promise<Array<Domain>> => {
  return analysissuffix;
}

/**
 * Get names for consultants
 */
const getConsultantPrefix = async (): Promise<Array<Domain>> => {
  return consultantprefix;
}

/**
 * Get names for consultants
 */
const getConsultantSuffix = async (): Promise<Array<Domain>> => {
  return consultantsuffix;
}

export const ApiService = {
  isDomainAvailable,
  isDotComDomainAvailable,
  getCvrCompanies,
  getCvrList,
  isInstagramHandleAvailable,
  isFacebookHandleAvailable,
  isTwitterHandleAvailable,
  isYoutubeHandleAvailable,
  checkTwitterHandle,
  getAllDomains,
  getPrefixWords,
  getSuffixWords,
  getAnalysisPrefix,
  getAnalysisSuffix,
  getConsultantPrefix,
  getConsultantSuffix
}