import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container } from 'reactstrap';
import logo from '../../assets/logo.png';
import Colors from '../../constants/Colors';

const ReactstrapNavbar: React.FunctionComponent = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggle = (): void => setIsOpen(!isOpen);

    return (
        <div style={background}>
            <Container>
                <Navbar expand="md" light>
                    <NavbarBrand href="/">
                        <img src={logo} style={logoStyles} alt="Logo" />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} style={togglerStyles} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink href="/analyse/" style={linkStyles}>Analyse</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/korte-domaener/" style={linkStyles}>Korte domæner</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://blog.firma-navn.dk/" style={linkStyles}>Blog</NavLink>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret style={linkStyles}>
                                    Brancher
                        </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        <NavLink href="/konsulent/" style={dropdownStyles}>Navne til konsulenter</NavLink>
                                    </DropdownItem>
                                    <DropdownItem />
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret style={linkStyles}>
                                    Hjemmeside
                        </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        <NavLink href="/hjemmeside/" style={dropdownStyles}>Hjemmeside</NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink href="/om-siden/" style={dropdownStyles}>Om siden</NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink href="https://blog.firma-navn.dk/firmanavn/" style={dropdownStyles}>Firmanavn: Læs vores guide</NavLink>
                                    </DropdownItem>
                                    <DropdownItem />
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Container>
        </div>
    );
}

const background = {
    backgroundColor: Colors.themeColor
} as React.CSSProperties;

const logoStyles = {
    height: 30,
    width: 'auto'
} as React.CSSProperties;

const linkStyles = {
    color: Colors.navigationLinks,
    opacity: 0.8,
    textDecoration: 'none'
} as React.CSSProperties;

const dropdownStyles = {
    color: Colors.almostBlack,
    textDecoration: 'none'
} as React.CSSProperties;

const togglerStyles = {
    backgroundColor: Colors.white,
    opacity: 0.8,
    textDecoration: 'none'
} as React.CSSProperties;

export default ReactstrapNavbar;