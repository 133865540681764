import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion"
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import Checkmark from '../../assets/checked.png';
import NotAvailable from '../../assets/not-available.png';
import Loader from '../../assets/rings-spinner.svg';
import { ApiService } from '../../services/ApiService';
import ApiEndpoints from '../../constants/ApiEndpoints';
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';

const DotComDomainCard = (props) => {
    const { text } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState(null);
    const [domainAvailable, setDomainAvailable] = useState(null);
    const domainLink = ApiEndpoints.affiliateBaseUrl + ApiEndpoints.domainBaseUrl + props.text;

    /**
     * The effect hook fetching data
     * for the component.
     */
    useEffect(() => {
        const fetchData = async () => {
          if (text !== null && text !== '') {
            setIsLoading(true);
            setTitle(text);
            const domainAvailability = await ApiService.isDotComDomainAvailable(text);
            setDomainAvailable(domainAvailability);
            setIsLoading(false);
          }
        }
        fetchData();
    }, [text])

    /**
     * Triggers an event sent to Google Analytics with
     * the domain click.
     */
    const handleClick = () => {
        GoogleAnalyticsService.triggerGoogleAnalyticsEventLabel('Domain','Click', props.text)
    }

    return (
        <motion.div className="col-lg-6 col-md-6 col-sm-6 col-12" variants={itemStyles}>
            <a href={domainLink} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                <div style={card} className="card elevation">
                    {isLoading && (
                        <div>
                            <h3 style={cardTitle}>{props.text}.com</h3>
                            <p style={cardSubtitle}>Checker domæner...</p>
                            <img style={loadingIndicator} src={Loader} alt="loading-ikon" />
                        </div>
                    )}
                    {!isLoading && domainAvailable && domainAvailable !== null && (
                        <div>
                            <h3 style={cardTitle}>{title}.com</h3>
                            <p style={cardSubtitle}>Gå til domæne →</p>
                            <img style={icon} src={Checkmark} alt="analyse-ikon" />
                        </div>
                    )}
                    {!isLoading && !domainAvailable && !domainAvailable !== null && (
                        <div>
                            <h3 style={cardTitle}>{title}.com</h3>
                            <p style={cardSubtitle}>Domænet er ikke ledigt</p>
                            <img style={icon} src={NotAvailable} alt="analyse-ikon" />
                        </div>
                    )}
                </div>
            </a>
        </motion.div>
        );
    }
  
  DotComDomainCard.propTypes = {
    text: PropTypes.string.isRequired
  };
  
  const itemStyles = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  
  const card = {
    backgroundColor: Colors.white,
    paddingLeft: 30,
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 80,
    marginBottom: 20,
    borderRadius: 15,
    border: "1px solid #E8EAED",
  };
  
  const cardTitle = {
    color: Colors.almostBlack,
    fontWeight: Variables.fontWeightHeadline,
    fontSize: 25,
    lineHeight: 1,
    marginBottom: 10
  };
  
  const cardSubtitle = {
    color: Colors.grey,
    fontWeight: Variables.fontSizeSubtitle,
    fontSize: 18,
    lineHeight: 1,
    marginBottom: 0,
  };

  const icon = {
    position: 'absolute',
    right: 25,
    top: 32,
  };

  const loadingIndicator = {
    width: 50,
    height: 50,
    color: Colors.red,
    position: 'absolute',
    right: 25,
    top: 32,
  };
  
  export default DotComDomainCard;