import React from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion"
import { DomainService } from '../../services/DomainService';
import AnalysisDomainCard from './AnalysisDomainCard';
import Colors from '../../constants/Colors';

export default class DomainListClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            domain: this.props.text,
            domainList: []
        }
    }

    /**
     * Check URL parameter and search if
     * it is present.
     */
    componentDidMount = async () => {
        const domains = await DomainService.createAnalysisDomainList(this.props.text);
        this.setState({ domainList: domains });
    }

    /**
     * When a new word is searched for.
     * Data is cleared and new data is retrieved.
     */
    componentDidUpdate = async (prevProps) => {
        if (prevProps.text !== this.props.text) {
            this.setState({ domainList: [] }, async () => {
                const domains = await DomainService.createAnalysisDomainList(this.props.text);
                this.setState({ domainList: domains });
            });
        }
    }

    render() {
        return(
            <div style={domainContainer}>
                <motion.div className="variantsContainer"
                variants={variantsContainer}
                initial="hidden"
                animate="visible">
                    <div className="container">
                        <div className="row">
                            {this.state.domainList.slice(0, 200).map((item, key) =>
                                <AnalysisDomainCard
                                item={item}
                                key={item.id}
                                />
                            )}
                        </div>                        
                    </div>
                </motion.div>
            </div>
        );
    }
}

DomainListClass.propTypes = {
    text: PropTypes.string.isRequired
};

const domainContainer = {
    backgroundColor: Colors.lightThemeColor
};

const variantsContainer = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
        delay: 0.1,
        when: "beforeChildren",
        staggerChildren: 0.03
        }
    }
};