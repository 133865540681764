import React from 'react'
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';

interface ComponentProps {
    fontSize?: number
}

const Dot: React.FunctionComponent<ComponentProps> = ({ fontSize }) => {

    const spanDot = {
        color: Colors.red,
        fontFamily: Variables.fontFamilyText,
        fontSize: fontSize
    } as React.CSSProperties;

    return(
        <span style={spanDot}>.</span>
    );
}

Dot.defaultProps = {
    fontSize: 60
} as Partial<ComponentProps>;

export default Dot;