import React from 'react';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';
import Dot from '../generalcomponents/Dot';

interface SectionHeadlineProps {
    children: string | string[],
    fontSize?: number,
    showDot: boolean,
    marginTop?: number
}

const SectionHeadline: React.FunctionComponent<SectionHeadlineProps> = ({ children, fontSize, showDot, marginTop }) => {

    const textStyle = {
        color: Colors.almostBlack,
        fontFamily: Variables.fontFamilyHeadline,
        fontWeight: Variables.fontWeightHeadline,
        fontSize: fontSize,
        lineHeight: 0.7,
        marginBottom: 20,
        marginTop: marginTop
    };

    return (
        <p style={textStyle}>
            {children}{showDot ? <Dot fontSize={50} /> : ''}
        </p>
    );
}

SectionHeadline.defaultProps = {
    fontSize: 30,
    showDot: true,
    marginTop: 0
  } as Partial<SectionHeadlineProps>;

export default SectionHeadline;