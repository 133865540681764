import React from 'react';
import { motion } from "framer-motion"

interface ComponentProps {
  children: any
};

const MotionContainer: React.FunctionComponent<ComponentProps> = ({ children }) => {
  return(
      <motion.div className="variantsContainer"
      variants={variantsContainer}
      initial="hidden"
      animate="visible">
        {children}
      </motion.div>
  );
}

const variantsContainer = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.1,
        when: "beforeChildren",
        staggerChildren: 0.03
      }
    },
};

export default MotionContainer;