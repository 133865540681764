import React from 'react';
import { motion } from "framer-motion"

interface ComponentProps {
  children: any,
  lg?: string,
  md?: string,
  sm?: string
};

const MotionRow: React.FunctionComponent<ComponentProps> = ({ children, lg, md, sm }) => {
  const classes: string = "col-lg-" + lg + " col-md-" + md + " col-sm-" + sm + " col-12";
  return(
    <motion.div className={classes} variants={itemStyles}>
        {children}
    </motion.div>
  );
}

MotionRow.defaultProps = {
  lg: "4",
  md: "6",
  sm: "6"
} as Partial<ComponentProps>;

const itemStyles = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
};

export default MotionRow;